import { Button } from 'primereact/button'
import React, { useRef, useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { Galleria } from 'primereact/galleria';
import { testPlanService } from '../../../../../services';
import { Toast } from 'primereact/toast';

const StepVideoUpload = ({ suiteId, testRunnerId, data }) => {
    const inputRef = useRef(null);
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [videos, setVideos] = useState([])
    const [serverVideos, setServerVideos] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false)
    const selectVideos = (e) => {
        serverVideos.length > 0 ?
            setVisible(prev => !prev) : inputRef.current.click()
    }
    useEffect(() => {

        console.log(data, "data")
        data && setServerVideos(data)
    }, [data])
    const itemTemplate = (item) => {
        const video = typeof item === "string" ?
            process.env.REACT_APP_STORAGE_PATH + item : URL.createObjectURL(item);
        console.log(item, "video");
        return <div className='card'>
            {/* <h6>{video}</h6> */}
            <video controls playsInline style={{ width: '100%' }}>
                <source src={video} />
            </video>
        </div>
    }

    const thumbnailTemplate = (item) => {
        const video = typeof item === "string" ?
            process.env.REACT_APP_STORAGE_PATH + item : URL.createObjectURL(item);

        return <video src={video} className='max-w-5rem' />
    }

    const inputHandler = (e) => {
        if (e.target.files.length > 0) {
            const getFiles = e.target.files
            console.log(getFiles)
            e.target.files && setVideos([...getFiles])
            setVisible(prev => true)
        } else {
            setVideos([])
            setVisible(false)
        }
    }
    const onItemChange = (event) => {
        setActiveIndex(event.index)
    }
    const SelectVideoButton = ({ label }) => (
        <Button raised size='small' className='w-auto flex-none' text tooltip={serverVideos > 0 ? "View Videos" : 'Upload Test Video'}
            label={label} icon={"pi pi-video"} onClick={selectVideos} tooltipOptions={{ position: "bottom" }} />
    )
    const Header = () => {
        return <div className='flex align-items-center'>
            <h4>Steps Video</h4>
            {!serverVideos.length > 0 && <span className='p-buttonset ml-auto mr-5'>
                <SelectVideoButton label="Change Videos" />
                <Button raised size='small' icon="pi pi-arrow-up-right" disabled={loading}
                    label='Submit Video' iconPos='right' onClick={uploadVideos} loading={loading} />
            </span>}
        </div>
    }

    const uploadVideos = () => {
        setLoading(prev => !prev)
        const formData = new FormData();
        formData.append("test_runner_id", testRunnerId)
        formData.append("test_suite_id", suiteId)
        if (videos.length) {
            for (let i = 0; i < videos.length; i++) {
                formData.append(`videos[${i}]`, videos[i]);
            }
        }
        testPlanService.saveStepVideo(formData).then(res => {
            console.log(res)
            setVisible(false)
            setVideos([])
            const data = typeof res.data === "string" ? JSON.parse(res.data) : res.data
            setServerVideos(data)
            setLoading(false)
            toast.current.show({ severity: 'success', summary: 'Videos', detail: res.message });
        }).catch(err => {
            setLoading(false)
            toast.current.show({ severity: 'danger', summary: 'Videos', detail: err.message });
            console.log(err)
        })
    }
    return (
        <>
            <Toast ref={toast} />
            <SelectVideoButton label={serverVideos.length > 0 ? "View Videos" : 'Upload Test Video'} />
            <input type='file' ref={inputRef} id='uploadvideos' accept='video/*' multiple className='max-w-0 absolute z-0' onChange={inputHandler} />
            <Dialog draggable={false} closeOnEscape={serverVideos.length > 0 ? true : false} header={Header} visible={visible} style={{ width: '50vw' }} onHide={() => { setVisible(prev => !prev); setVideos([]) }}>
                <Galleria value={serverVideos.length > 0 ? serverVideos : videos} numVisible={5} style={{ maxWidth: '100%' }} circular
                    item={itemTemplate} showItemNavigators showItemNavigatorsOnHover thumbnail={thumbnailTemplate}
                    activeIndex={activeIndex} onItemChange={onItemChange}
                />
            </Dialog>
        </>
    )
}

export default StepVideoUpload