import React, { useRef } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import './style.scss';
import Galleria from './Galleria';
import { Tooltip } from 'primereact/tooltip';

export default function Toggleable({ data, index, onFailed, MarkStatusButtons, noActionBtn }) {
  const galleria = useRef(null);
  // get a button
  const button = (severity, tooltip, icon, disabled) => {
    return <Button icon={`pi pi-${icon}`} rounded raised severity={severity} disabled={disabled} className={` action-button`} aria-label='cancel' outlined={disabled} tooltip={tooltip} />;
  };
  let bgColor = '#fff';
  if (data.is_passed === true) {
    bgColor = '#e8f7ed';
  }
  if (data.is_passed === false) {
    bgColor = '#f5eeee';
  }
  const legendTemplate = (
    <div className='flex align-items-center w-full gap-3 justify-content-between '>
      <div className='flex gap-3  align-items-center '>
        <Badge value={index + 1}></Badge>

        <div data-pr-tooltip={data.description} className=' header-text ' style={{ maxWidth: '100%' }}>
          {data.description}
        </div>
      </div>

      {!noActionBtn && (
        <div className='flex gap-2'>
          {!MarkStatusButtons ? (
            <>
              {data.is_passed !== undefined && data.is_passed && button('success', 'Marked Passed', 'check')}
              {data.is_passed !== undefined && !data.is_passed && button('danger', 'Marked Failed', 'times')}

              {data.is_passed === undefined && (
                <>
                  {button('success', 'Marked Passed', 'check', true)}
                  {button('danger', 'Marked Failed', 'times', true)}
                </>
              )}
            </>
          ) : (
            MarkStatusButtons
          )}
        </div>
      )}
    </div>
  );
  return (
    <div>
      <Fieldset
        key={index.toString()}
        legend={legendTemplate}
        className={`mb-3`}
        style={{ background: bgColor ?? '#ffffff', padding: '0px 10px' }}
        pt={{
          legend: { style: { background: bgColor ?? '#ffffff' } },
          legendTitle: { className: 'text-danger' },
          togglerIcon: { className: 'text-white' },
        }}
      >
        <p className='m-0'>
          <strong>Parameter Value: </strong>
          {data.value}
        </p>
        <div className='flex'>
          {data.hasOwnProperty('comment') && !data?.is_passed && (
            <div className=' flex gap-3 flex-1'>
              <strong>Comment:</strong>
              {data.comment}
            </div>
          )}
          {data.images && (
            <div className='flex align-items-center gap-2'>
              <Button tooltip='View Images' tooltipOptions={{ position: 'bottom' }} icon='pi pi-images' className='action-button' rounded onClick={() => galleria.current.show()} />
            </div>
          )}
        </div>
        {data.hasOwnProperty('comment') && data?.comment === '' && !data?.is_passed && <>{onFailed ? onFailed : ''}</>}
      </Fieldset>
      {data.images && (
        <>
          <Galleria ref={galleria} images={data.images} />
        </>
      )}
      <Tooltip position='bottom' target={'.header-text'} />
    </div>
  );
}
