import {suiteActions} from "../actions";

const initialState = {
   componentsForTest:[],
    activeComponent: null,
    // prevActiveIndex:null,
     activeIndex:null

};

const SuiteReducer = (state = initialState, action) => {
    // console.log({action,state});
    const { data } = action

    switch (action.type) {
        case suiteActions.SET_COMPONENTS_FOR_TEST:
            return { ...state, componentsForTest: data }

        case suiteActions.ADD_COMPONENTS_FOR_TEST:
            return {...state,componentsForTest: [...state.componentsForTest,data]}

        case suiteActions.SET_ACTIVE_COMPONENT:
            const {activeComponent,componentsForTest}=data
            return {...state,activeComponent,componentsForTest}

        case suiteActions.SET_ACTIVE_INDEX:

            return {...state,activeIndex:data }

        case suiteActions.RESET_SUITE:
            return {
                componentsForTest:[],
                activeComponent: null,
                // prevActiveIndex:null,
                activeIndex:null

            }
        // case:'SET_DATASET'
        //
        //     return {...state,componentsForTest: data}
        default:
            // console.log({initialState},'defualt')
            return state

    }
}

export default SuiteReducer;
