import { useCallback, useState } from 'react';

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    openModal,
    closeModal,
  };
};



export const useCollapsed = () => {
  const [isCollapsed, setToggleCollapsed] = useState(false);

  const toggleCollapsed = useCallback(() => {
    setToggleCollapsed(prev => !prev);
  }, []);

  return {
    isCollapsed,
    toggleCollapsed,
  };
};
