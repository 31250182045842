const initialState = {
    info: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        token: null,
        isLoggedIn:false
    }
   };

   const AuthReducer = (state = initialState, action) => {
     // console.log({action,state});
     switch (action.type) {
         case 'SET_AUTH_INFO':
             const {data}=action
             return {...state, info:data}

        case 'RESET':
            return {
                info: {
                    id: null,
                    firstname: null,
                    lastname: null,
                    email: null,
                    token: null,
                    isLoggedIn:false
                }
            }
         default:
             // console.log({initialState},'defualt')
             return state

     }
 }

   export default AuthReducer;
