import React, { useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "react-query";
import defectService from "../../services/defects.service";
import { getBadgeType } from "../../utils/common";
import { Tag } from "primereact/tag";

const StatusDropdowns = ({ defectId, statusId }) => {
    const dropdownRef = useRef();
    const [status, setStatus] = useState(statusId);
    const { data, isLoading, isError } = useQuery("statusOptions", fetchStatusOptions);
    const queryClient = useQueryClient();
    const updateStatus = useMutation({
        mutationKey: "updateStatus",
        mutationFn: updateStatusOption,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["defects"] });
        },
    });

    const handleStatusChange = (event) => {
        const newStatusId = event.value;
        console.log(event.value, "event");
        setStatus((prev) => (prev = newStatusId));
        updateStatus.mutate({ defectId, status_id: newStatusId });
    };

    const handleTagClick = () => {
        dropdownRef.current &&  dropdownRef.current.click()
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching status options</div>;
    }

    const statusOptions = data || [];
    const statusName = statusOptions.find((status) => status.id === statusId)?.name || "";
    return (
        <div className="relative">
     
            <Tag className="cursor-pointer  z-3 block w-fit text-white"  severity={getBadgeType(statusName)} onClick={handleTagClick}>

                <Dropdown
                 inputRef={dropdownRef}
                 className="tag-dropdown" 
                 value={status ?? ""}
                 options={statusOptions}
                 placeholder="Select a status"
                 optionLabel="name"
                 optionValue="id"
                 onChange={handleStatusChange}
                 />
                 </Tag>
           
        </div>
    );
};

async function fetchStatusOptions() {
    // Fetch status options from an API endpoint
    const response = await defectService.getDefectStatus();
    return response.data;
}

async function updateStatusOption(data) {
    console.log(data.defectId, data.status_id, "updated status");
    // Update the status option using an API endpoint
    await defectService.updateStatus(data.defectId, { status_id: data.status_id });
}

export default StatusDropdowns;
