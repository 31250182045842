import React, { useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import Toggleable from '../TestPlan/Logs/Fieldset';

const Modal = ({ visible, setVisible, testPlanItem }) => {
    useEffect(() => {
        console.log(testPlanItem, "steps")
    }, [testPlanItem])

    return (
        <Dialog header={testPlanItem?.test_plan_item?.test_suite_name} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            {testPlanItem?.test_plan_item?.added_steps &&
                testPlanItem.test_plan_item.added_steps.map((step, index) => (

                    <Toggleable data={step} index={index} key={index.toString()} />
                ))
            }
        </Dialog>
    )
}

export default Modal