import React, { useEffect, useState, useRef, useCallback } from 'react'
import { testPlanService } from '../../../services'
import { useParams } from 'react-router-dom'
import { InputText } from 'primereact/inputtext';
import { testPlanActions } from '../../../store/actions';
import { useDispatch, useSelector } from "react-redux";
import { TabView, TabPanel } from 'primereact/tabview';
import { clone } from '../../../utils/common';
import LoaderButton from '../../../components/common/loaderButton/LoaderButton';
import { useNavigate } from 'react-router-dom';
import DefineTab from './DetailTabs/DefineTab';
import ExecuteTab from './DetailTabs/ExecuteTab';
import { Tooltip } from 'primereact/tooltip';
import { ConfirmPopup } from 'primereact/confirmpopup';
import UpdatePlanItem, { deletePlanItem, updatePlanItem } from './UpdatePlanItem';
import { useQuery, useQueryClient } from 'react-query';
export default function TestPlanDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch()
    const queryClient = useQueryClient();
    const details = useSelector((state) => state.testPlan.details)
    const selectedItemIndex = useSelector((state) => state.testPlan.activeItemIndex)

    const [addItemSubmitting, setaddItemSubmitting] = useState(false)
    const [showAddItem, setshowAddItem] = useState(false)

    const itemInputRef = useRef(null)

    const onSelectItem = useCallback((index) => {
        dispatch({ type: testPlanActions.SET_ACTIVE_TTEM_INDEX, data: index })
        return () => {
            dispatch({ type: testPlanActions.CLEAR_ALL })
        }
    }, [dispatch])




    useEffect(() => {
        if (details.items.length > 0 && selectedItemIndex === null) {
            onSelectItem(0)
        }
    }, [details.items, selectedItemIndex, onSelectItem])
    const { refetch: getTestPlans } = useQuery({
        queryKey: ["testPlanDetails"],
        queryFn: async () => {
            const res = await testPlanService.getTestPlan(params.id)
            return res
        },
        onSuccess: (res) => {
            dispatch({ type: testPlanActions.SET_TESTPLAN_DETAILS, data: res.data })
        },
        onError: (err) => {
            console.log(err)
        }
        , enabled: true,
        cacheTime: Infinity,
        staleTime: Infinity
    })

    useEffect(() => {
        getTestPlans()
    }, [params.id, dispatch])



    const addItem = () => {
        setaddItemSubmitting(true)
        const data = {
            name: itemInputRef.current.value,
            test_plan_id: params.id
        }
        testPlanService.addItem(data)
            .then((res) => {
                let _detail = clone(details)
                _detail.items.push(res.data)
                dispatch({ type: testPlanActions.SET_TESTPLAN_DETAILS, data: _detail })
                itemInputRef.current.value = ''
                setshowAddItem(false)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setaddItemSubmitting(false)
            })
    }




    const goBack = () => {
        navigate(-1)
        dispatch({ type: testPlanActions.CLEAR_ALL })
    }
    return (
        <div className="grid ">
            <Tooltip target=".tooltip" />
            <ConfirmPopup />
            <div className="col-12">
                <div className='card'>
                    <div className='grid'>
                        <div className='col-3'>
                            <div>
                                <span className={'cursor-pointer mr-3'}>
                                    <i className={`pi pi-arrow-left`} style={{ fontSize: '18px' }} onClick={goBack}></i>
                                </span>
                                <span className='font-bold' >{details?.name}</span>
                                {/* <div className='ml-5 pt-2'>
                                    <div className='p-2'>Oct 22 - Oct 29 Past</div>
                                    <div className='p-2'>75% run, 66 passed. View Report</div>
                                </div> */}
                            </div>
                            <div className='bg-black-alpha-10 p-2 border-round mt-2'>
                                <div className='flex justify-content-between'>
                                    <span className='font-bold' >Test Suites</span>
                                    <div>
                                        {
                                            !showAddItem ?
                                                <i className={`pi pi-plus cursor-pointer`} style={{ fontSize: '15px' }} onClick={() => setshowAddItem(true)} ></i> :
                                                <i className={`pi pi-minus cursor-pointer`} style={{ fontSize: '15px' }} onClick={() => setshowAddItem(false)} ></i>
                                        }


                                    </div>
                                </div>

                            </div>

                            <div className='ml-2'>
                                <div className='pt-2 pb-2 '>
                                    <span>{details?.name}</span>
                                </div>
                                <div className='ml-4'>
                                    <div className='mt-2 mb-2'>
                                        {
                                            showAddItem &&
                                            <div className="p-inputgroup">
                                                <InputText ref={itemInputRef} className="p-inputtext-sm" placeholder="Item Name" />
                                                <LoaderButton keepText loading={addItemSubmitting} icon="pi pi-plus" onClick={() => addItem()} className="p-button-sm">

                                                </LoaderButton>
                                            </div>
                                        }


                                    </div>
                                    {
                                        details.items.map((item, index) => {
                                            return <div key={item.id} className={`p-2 cursor-pointer border-round ${index === selectedItemIndex ? 'bg-primary' : ''}`}
                                                onClick={() => onSelectItem(index)}>
                                                <div className='flex gap-1 align-items-center '>
                                                    <p data-pr-tooltip="No notifications"
                                                        data-pr-position="right"
                                                        className='mb-0 flex-1 tooltip text-overflow-ellipsis white-space-nowrap overflow-hidden'> {item.id}: {item.name}</p>
                                                    <UpdatePlanItem getTestPlans={getTestPlans} index={index} item={item} testPlanId={details?.id} />

                                                </div>
                                            </div>

                                        })
                                    }
                                </div>
                            </div>


                        </div>
                        {
                            selectedItemIndex !== null &&

                            <div className='col-9 ' style={{ borderLeft: '1px solid #EEEEEE' }}>
                                {<h6 className='font-bold'>{details.items[selectedItemIndex].id + " : " + details.items[selectedItemIndex].name}</h6>}

                                <TabView>
                                    <TabPanel header="Define">
                                        <DefineTab />
                                    </TabPanel>
                                    <TabPanel header="Execute">
                                        <ExecuteTab />
                                    </TabPanel>

                                </TabView>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}



