import { Button } from 'primereact/button'
import React, { useState, useRef } from 'react'
import { testPlanService } from '../../../../services'
import { useParams } from 'react-router-dom'
import { testPlanActions } from '../../../../store/actions';
import { useDispatch, useSelector } from "react-redux";
import { clone } from '../../../../utils/common';
import LoaderButton from '../../../../components/common/loaderButton/LoaderButton';
import TestPlanItemAddedSuitesList from './TestPlanItemAddedSuitesList';
import { Dialog } from 'primereact/dialog';
import AlertMsg from '../../../../components/common/AlertMsg/AlertMsg';
import secureLocalStorage from '../../../../utils/secureLocalStorage';
import CommentAndImage from "./OnfaildResponse/CommentAndImage"
import Fieldset from "./../../Logs/Fieldset"
import StepVideoUpload from "./StepVideoUpload"
import BlukStepsUpdate from "./BulkStepsUpdate"
import { Dropdown } from 'primereact/dropdown';
import { TieredMenu } from 'primereact/tieredmenu';
import { useMutation } from 'react-query';
export default function ExecuteTab() {
    const params = useParams();
    const menu = useRef(null);
    const dispatch = useDispatch()
    const details = useSelector((state) => state.testPlan.details)
    const selectedItemIndex = useSelector((state) => state.testPlan.activeItemIndex)
    const [bulkStepsUpdate, setBulkStepsUpdate] = useState();
    const [executeModal, setExecuteModal] = useState(false)
    const [saveAndCloseLoading, setSaveAndCloseLoading] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])

    const [tests, setTests] = useState([])
    const [testRunner, setTestRunner] = useState(null)

    const [currentTestIndex, setCurrentTestIndex] = useState(0)
    const bulkStepsUpdateProps = {
        bulkStepsUpdate,
        setBulkStepsUpdate,
        setTests,
        currentTestIndex,
        testRunnerId: testRunner?.id,
        suiteId: tests[currentTestIndex]?.test_suite?.id
    }


    const hideExecuteTestModal = () => {
        //need to rewrite this logic
        setExecuteModal(false)
        const controller = new AbortController()
        testPlanService.getTestPlan(params.id, controller).then((res) => {
            dispatch({ type: testPlanActions.SET_TESTPLAN_DETAILS, data: res.data })
            dispatch({ type: testPlanActions.SET_ACTIVE_TTEM_INDEX, data: null })
            setTimeout(() => dispatch({ type: testPlanActions.SET_ACTIVE_TTEM_INDEX, data: selectedItemIndex }), 100);
        }).catch((err) => {
            console.log(err)
        })
        return () => {
            controller.abort()
        }
    }

    const run = async () => {
        const data = {
            test_suites: selectedRows,
            test_plan_item_id: details.items[selectedItemIndex].id
        }
       const res = await testPlanService.getTestSuitsSteps(data)
       return res
    }

    const runForLocal = async () => {
        const data = {
            test_suites: selectedRows,
            test_plan_item_id: details.items[selectedItemIndex].id,
        }
       const res = testPlanService.getTestSuitsStepsForLocal(data)
          return res
    }

    const { mutate, isLoading } = useMutation({
        mutationFn: async (e) => {
          if (selectedRows.length === 0) {
            throw new Error('Select at least one test!');
          }
          setExecuteMethod(e?.item?.label);
          const currentExecuteMethod = e?.item?.value;
          const result = currentExecuteMethod === 1 ? await run() : await runForLocal();
          return { currentExecuteMethod, result : result?.data };
        },
        onSuccess: async (res) => {
          if(res?.currentExecuteMethod === 1) {
              setTests(res.result.test_runner_steps)
              setTestRunner(res.result.test_runner);
              setExecuteModal(true)
        }
        if(res?.currentExecuteMethod === 2) {
           const payload=  {
                ...res.result,
                token: secureLocalStorage.getItem('currentUser')?.token || ''
            }
             await testPlanService.runTestOnLocal(payload);
          }
        },
        onError: (error) => {
          alert(error);
          console.error(error, "error run for local");
        },
      });

    const markStepStatus = (index, status, finallyCallback, comment = null, images = []) => {
        const currentTest = clone(tests[currentTestIndex])
        const step = currentTest.steps[index]
        if (status === false) {
            step.comment = comment || ''
        }

        const steps = [{ ...step, is_passed: status }]

        const formData = new FormData();
        formData.append("test_runner_id", testRunner.id)
        formData.append("test_suite_id", currentTest.test_suite.id)
        formData.append("steps", JSON.stringify(steps));

        if (images.length) {
            for (let i = 0; i < images.length; i++) {
                formData.append(`images[${i}]`, images[i]);
            }
        }
        finallyCallback(true)
        testPlanService.saveStepStatus(formData)
            .then((res) => {
                setTests((tests) => {
                    tests[currentTestIndex].steps[index].is_passed = status
                    if (status === false) {
                        tests[currentTestIndex].steps[index].comment = comment || ''
                        console.log(tests[currentTestIndex].steps[index].comment)
                    }
                    return clone(tests)
                })
            }).catch((err) => {
                console.log(err)
            }).finally(() => finallyCallback(false))
    }

    const saveAndClose = () => {
        const currentTest = clone(tests[currentTestIndex])
        const data = {
            test_runner_id: testRunner.id,
            test_suite_id: currentTest.test_suite.id,
            test_plan_item_id: details.items[selectedItemIndex].id
        }
        setSaveAndCloseLoading(true)
        testPlanService.saveAndClose(data)
            .then((res) => {
                if (tests.length > 1) {
                    if (currentTestIndex < (tests.length - 1)) {
                        setCurrentTestIndex(currentTestIndex + 1)
                        setBulkStepsUpdate(null)
                    }
                    if (currentTestIndex === (tests.length - 1)) {
                        hideExecuteTestModal()
                    }
                } else {
                    hideExecuteTestModal()
                }
            }).catch((err) => {

            }).finally(() => setSaveAndCloseLoading(false))
    }


    const Header = () => (
        <div className='flex align-items-center '>
            <h4>Steps</h4>
            <div className='ml-auto mr-5'>
                <span className=' p-buttonset  rounded  '>
                    <StepVideoUpload testRunnerId={testRunner?.id} suiteId={tests[currentTestIndex]?.test_suite?.id} />
                    <LoaderButton
                        keepText
                        size={"small"}
                        className={'w-auto '}
                        loading={saveAndCloseLoading}
                        tooltip={"Save All"}
                        tooltipPosition="bottom"
                        onClick={saveAndClose}>Save & Close</LoaderButton>
                </span>
            </div>
        </div>
    )

    const [executeMethod, setExecuteMethod] = useState(null);
    const executeOptions = [
        { label: 'Run Now', value: 1, command: (e) => mutate(e) },
        { label: 'Run On Local', value: 2, command: (e) => mutate(e) },
        { label: 'Run on Cloud', value: 3, disabled: true,}
    ];
    return <>
    <div className="flex justify-content-end mb-3">
            <TieredMenu model={executeOptions} popup ref={menu} breakpoint="767px" />
            <Button loading={isLoading} label={executeMethod ??  "Select an Execute method"} icon="pi pi-bars" raised size='small' text  onClick={(e) => menu.current.toggle(e)} />
        </div>
        <div className=''>
            {/* <Dropdown value={executeMethod} onChange={excuteHandler} options={executeOptions}
                placeholder="Select an Execute method" className="w-full md:w-14rem"  /> */}
            {/* <Button raised label='Run For Application' onClick={() => run()} className='p-button-sm' />
            <Button raised text label='Run on Local' onClick={() => runForLocal()} className='p-button-sm' /> */}
        </div>
        <TestPlanItemAddedSuitesList usingIn="execute" selectedRowsCallback={setSelectedRows} />

        <Dialog closeOnEscape={false} draggable={false} visible={executeModal} maximizable style={{ width: '1000px' }} header={<Header />} modal className="p-fluid" onHide={hideExecuteTestModal} >
            <div>
                {
                    tests.length > 0 && <>

                        <div className='flex justify-content-between align-items-center gap-4 mb-4 p-1'>
                            <h5 className='mb-0'>
                                {tests[currentTestIndex].test_suite.name}
                            </h5>
                            <BlukStepsUpdate {...bulkStepsUpdateProps} />

                        </div>

                        <div className='p-dialog-content p-0'>
                            {
                                (!tests[currentTestIndex]?.steps || tests[currentTestIndex]?.steps?.length === 0) &&
                                <AlertMsg><div className="ml-2">No Steps.</div></AlertMsg>
                            }

                            {
                                tests[currentTestIndex]?.steps?.map((step, i) => {
                                    return (
                                        <Fieldset data={step} index={i} MarkStatusButtons={<MarkStatusButtons index={i} step={step} markStepStatus={markStepStatus} />}
                                            onFailed={<CommentAndImage index={i} callback={markStepStatus} />} />
                                    )
                                })
                            }
                        </div>


                    </>
                }

            </div>
        </Dialog>
    </>

}


function MarkStatusButtons({ index, markStepStatus, step }) {
    console.log(step)
    const [markStepLoading, setMarkStepLoading] = useState(false)

    if (markStepLoading) {
        return <span className="loader" style={{ borderTop: '3px solid #2196f3' }}></span>
    }

    const checkAleadyMark = (forStatus) => {
        if (step.is_passed !== undefined) {
            if (forStatus === 'passed' && step.is_passed === true) {
                return true
            }
            if (forStatus === 'failed' && step.is_passed === false) {
                return true
            }
        } else {
            return false
        }
    }

    const assignClasses = (forStatus) => {
        // console.log(step,step.is_passed)
        if (step.is_passed === undefined) {
            return 'p-button-rounded p-button-outlined'
        }

        if (forStatus === 'passed' && step.is_passed === true) {
            return 'p-button-rounded '
        }

        if (forStatus === 'failed' && step.is_passed === false) {
            return 'p-button-rounded '
        }

        return 'p-button-rounded p-button-outlined'

        // if(checkAleadyMark(forStatus)){
        //     return 'p-button-rounded  w-2rem h-2rem'
        // }else{
        //     return 'p-button-rounded p-button-text w-2rem h-2rem'
        // }
    }

    const statusClickHandler = (forStatus) => {
        // if(checkAleadyMark(forStatus)){
        //     return false
        // }else{
        markStepStatus(index, forStatus === 'passed', setMarkStepLoading)
        // }
    }

    return <>
        <Button onClick={() => statusClickHandler('failed')} icon="pi pi-times" rounded raised
            className={assignClasses('failed') + ' p-button-danger action-button ml-1'} aria-label="failed"
            tooltip={checkAleadyMark('failed') ? 'Marked Failed' : 'Mark Failed'} tooltipOptions={{ position: "bottom" }} />

        <Button onClick={() => statusClickHandler('passed')} icon="pi pi-check" rounded raised
            className={assignClasses('passed') + ' p-button-success action-button ml-1'} aria-label="passed"
            tooltip={checkAleadyMark('passed') ? 'Marked Passed' : 'Mark Passed'} tooltipOptions={{ position: "bottom" }} />
    </>
}
