import { testPlanActions } from "../actions";

const initialState = {
    details: {
        name: '',
        items: [],
    },
    added_suites: [],
    activeItemIndex: null

};

const TestPlanReducer = (state = initialState, action) => {
    // console.log({action,state});
    const { data } = action

    switch (action.type) {
        case testPlanActions.SET_TESTPLAN_DETAILS:
            return { ...state, details: data }

        case testPlanActions.SET_ACTIVE_TTEM_INDEX:
            return { ...state, activeItemIndex: data }

        case testPlanActions.SET_ADDED_SUITES:
            console.log('____________',data)
            return { ...state, added_suites: data }

        case testPlanActions.CLEAR_ALL:
            return {
                details: {
                    name: '',
                    items: []
                },
                added_suites: [],
                activeItemIndex: null
            }

        default:
            // console.log({initialState},'defualt')
            return state

    }
}

export default TestPlanReducer;
