import { Dialog } from "primereact/dialog";
import React, { useRef } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Toast } from 'primereact/toast';
import { useForm, Controller } from "react-hook-form"
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import {  useMutation } from 'react-query';
import defectService from "../../../services/defects.service";
const CreateGlitch = ({ visible, setVisible, testRunnerItem, refetchLogs }) => {
    const toast = useRef(null);
    const { id: test_runner_item_id } = testRunnerItem
    const validationSchema = yup.object().shape({
        comment: yup.string().required("Provide some comment"),
    });
    const { handleSubmit, control, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { mutate: createGlitch , isLoading } = useMutation(async (newData) => {
        const response =  await defectService.logFailedReason(test_runner_item_id ,newData);
        return response;
    }, {
        onError: (error) => {
            console.log(error , "error");
            toast.current.show({ severity: 'warn', summary: 'Error', detail: error.response.data?.message ?? error.message });
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        },
        onSuccess: async (res) => {
            toast.current.show({ severity: 'success', summary: 'Info', detail: res.message });
            refetchLogs()
            closeModal()
        },
    });
    const onSubmit = async (data) => {
      createGlitch(data);
    };
    const closeModal = () => {
        reset({
          comment : ""
        })
        // setCurrentItem({})
        setVisible(false);
    };
    return (
        <Dialog header={`Put this log in the Glitch`} visible={visible} style={{ width: "50vw" }} onHide={() => closeModal()}>
            <Toast ref={toast} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-column gap-2">
                    <label htmlFor="comment" className=" font-medium ">Comments <small>(Describe why this is a glitch rather than a failure)</small></label>
                    <Controller control={control} name="comment" render={({ field: { onChange, value } }) => <InputTextarea  id="comment" aria-describedby="comment-help" value={value ?? ""} rows={5} onChange={onChange}  />} />
                    <p className="text-red-600">{errors.comment?.message}</p>
                </div>
                <span className={"p-buttonset  text-right mt-5 block"}>
                    <Button label="Submit" type="submit" size="small" loading={isLoading} disabled={isLoading}></Button>
                    <Button label="Cancel" type="button" size="small" severity="danger" onClick={closeModal}></Button>
                </span>
            </form>
        </Dialog>
    );
};

export default CreateGlitch;
