import React from 'react'

export default function AlertMsg(props) {
    return (
        <div className="p-message p-component p-message-info p-message-enter-done">
            <div className="p-message-wrapper">
                {props.children}
            </div>
        </div>
    )
}
