import React, {useState, useEffect, useRef} from 'react'

import LoaderButton from '../../components/common/loaderButton/LoaderButton'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
// import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';


import { projectService } from '../../services'
import { Formik, ErrorMessage } from 'formik';
// import api from '../../utils/api'
import * as Yup from 'yup';
import {genericTermService} from '../../services'


export default function ProjectAdd(props) {

  const projectObj={
    project_name: '',
    description: '',
    type_id:'',
    version:''
  }
    const toast = useRef()
    const [fields, setFields] = useState(projectObj)
  const [projectTypeOptions, setProjectTypeOptions] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {

    const controller = new AbortController();

    genericTermService.getProjectTypes()
      .then(({ data }) => {
        // setProjectTypeOptions(data.map((opt)=>({name:opt.name,code:opt.id})))
        setProjectTypeOptions(data)
      })
      .catch((err) => {

      })
      return () => {controller.abort()}

  }, [])

  useEffect(() => {
    const controller = new AbortController();
    if (props.id) {
      projectService.getProject(props.id, controller)
        .then(({ data }) => {
          setFields({
            project_name: data.project_name,
            description: data.description,
            type_id: data.type_id,
            version: data.version
          })
          setIsEdit(true)
        })
        .catch((err) => {

        })
    }

    return () => { controller.abort() }
  }, [props.id])




  const submit=(values, { setSubmitting,resetForm  })=>{

    setSubmitting(true)
    if(!isEdit){

      projectService.createProject(values)
      .then(({data})=>{
        setFields(projectObj)
          toast.current.show({ severity: 'success', summary: 'Added ', detail: 'Project has been added.' });
          //resetForm()
        props.onProjectSubmitSuccess()
      }).catch((err)=>{

        console.log(err)
      }).finally(()=>setSubmitting(false))

    }else{

      projectService.updateProject(props.id,values)
      .then(({data})=>{
          toast.current.show({ severity: 'success', summary: 'Updated ', detail: 'Project has been updated.' });
          props.onProjectSubmitSuccess()

      }).catch((err)=>{

      }).finally(()=>setSubmitting(false))
    }
  }

  const validationSchema=Yup.object({
    project_name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    type_id: Yup.string().required('Type is required'),
})

  const Err = ({ name }) => {
    return <div style={{ color: '#ff4032' }}>
      <ErrorMessage name={name} />
    </div>
  }
  return (
    <>
        <Toast ref={toast} />

        <Formik initialValues={fields} onSubmit={submit} enableReinitialize  validationSchema={validationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,}) => (
        <div>
          {/* <h5>Advanced</h5> */}
          <form onSubmit={handleSubmit}>
            <div className="p-fluid formgrid grid">
              <div className="field col-12 md:col-6">
                <label htmlFor="name">Project Name</label>
                <InputText id="project_name" name='project_name' type="text" onChange={handleChange} value={values.project_name}/>
                <Err name='project_name'/>

              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="version">Version</label>
                <InputText id="version" name="version" type="text" onChange={handleChange} value={values.version}/>
              </div>

              <div className="field col-12 md:col-12">
                <label htmlFor="description">Description</label>
                <InputTextarea id="description" rows={5} onChange={handleChange} value={values.description}/>
                <Err name='description'/>
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="primaryTestManagement">Primary Test Management</label>
                <Dropdown
                  id="primaryTestManagement"
                  // value={dropdownItem}
                  // onChange={(e) => setDropdownItem(e.value)}
                  // options={dropdownItems}
                  optionLabel="name" placeholder="Select One"></Dropdown>
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="primaryTicketingSystem">Primary Ticketing System</label>
                <Dropdown
                  id="primaryTicketingSystem"
                  // value={dropdownItem}
                  // onChange={(e) => setDropdownItem(e.value)}
                  // options={dropdownItems}
                  optionLabel="name" placeholder="Select One"></Dropdown>
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="type_id">Type</label>
                {/* <Dropdown
                id="type_id"
                name="type_id"
                value={values.type_id}
                onChange={(e)=>{console.log(e)}}
                options={projectTypeOptions}
                optionLabel="name" placeholder="Select One"></Dropdown> */}
                <select className='p-inputtext p-component' name='type_id' id='type_id' onChange={handleChange}  value={values.type_id?parseInt(values.type_id):''}>
                  <option value='' >Select Type</option>
                  {projectTypeOptions.map((opt) => <option  key={opt.id}  value={opt.id}>{opt.name}</option>)}
                </select>
                <Err name='type_id'/>

              </div>

            </div>
            <div style={{ textAlign: 'right' }}>

              {/*<Button label="Cancel" type='button' icon="pi pi-times" className="p-button-text w-auto" />*/}
              <LoaderButton
                keepText
                type='submit'
                icon={!isSubmitting && "pi pi-check"}
                className="p-button-text w-auto"
                loading={isSubmitting}
                spinnerStyle={{borderTop:'3px solid #6366f1'}}>
                  Save
              </LoaderButton>

            </div>


          </form>
        </div>
      )}
    </Formik>
    </>
    )
}
