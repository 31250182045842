import api from "../utils/api";
class TestSuiteService {

    /**
     *
     * @param {object} data Object of test suite data
     * @returns
     */
    createTestSuite(data) {
        const config = { url: '/test-suite', method: 'post', data }
        return api.request(config)
    }

    /**
     *
     * @param {string} id id of test suite
     * @param {AbortController} abortController to abort request on unmount
     * @returns
     */
    getTestSuite(id, abortController) {

        const config = { url: `/test-suite/${id}`, method: 'get', ...(abortController && { signal: abortController.signal }) }
        return api.request(config)
    }

    /**
     *
     * @param {AbortController} abortController to abort request on unmount
     * @param {string} id  of the parent folder
     * @returns
     */
    getTestSuites(id, abortController) {
        const config = {
            url: '/test-suite',
            method: 'get',
            params: { folder_id: id },
            ...(abortController && { signal: abortController.signal })
        }
        return api.request(config)
    }

    /**
     *
     * @param {string} id id of role to update
     * @param {Object} data Object of test suite data
     * @returns
     */
    updateTestSuite(id, data) {
        const config = { url: `/test-suite/${id}`, method: 'put', data }
        return api.request(config)
    }

    /**
     *
     * @param abortController  abortController to abort request on unmount
     * @returns {Promise<*>}
     */
    getTestSuiteStates(abortController) {
        const config = { url: `/testsuite/states`, method: 'get', ...(abortController && { signal: abortController.signal }) }
        return api.request(config)
    }

    /**
     *
     * @param {string} id id of testsuite
     * @returns
     */
    deleteTestSuite(id) {
        const config = { url: `/test-suite/${id}`, method: 'delete' }
        return api.request(config)
    }

    /**
   *
   * @param {AbortController} abortController to abort request on unmount
   * @param {Object} Object of query params
   * @returns
   */
    overRideValues(abortController, params = null) {
        const config = {
            url: '/save/override/value',
            method: 'post',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }
}
const testSuiteService = new TestSuiteService()
export default testSuiteService
