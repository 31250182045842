import api from "../utils/api";

class RoleService {


    /**
     * 
     * @param {integer} page 
     * @param {integer} limit 
     * @param {AbortController} abortController 
     * @returns 
     */
    getPermissions(page, limit, abortController) {
        const config = {
            url: '/permissions',
            params: {
                page: page,
                limit: limit
            },
            method: 'get',
            ...(abortController && { signal: abortController.signal })
        }
        return api.request(config)
    }

    /**
     * 
     * @param {object} data Object of role data 
     * @returns 
     */
    createRole(data) {
        const config = { url: '/roles', method: 'post', data }
        return api.request(config)
    }

    /**
     * 
     * @param {integer} page 
     * @param {integer} limit 
     * @param {AbortController} abortController 
     * @returns 
     */
    getRoles(page, limit, abortController) {
        const config = {
            url: '/roles',
            params: {
                page: page,
                limit: limit
            },
            method: 'get',
            ...(abortController && { signal: abortController.signal })
        }
        return api.request(config)
    }
     /**
     * 
     * @param {string} id id of role
     * @param {AbortController} abortController to abort request on unmount
     * @returns 
     */
      getRole(id,abortController){
    
        const config={ url: `/roles/${id}`, method: 'get',...(abortController&&{signal: abortController.signal})}
        return api.request(config)
    }
    /**
     * 
     * @param {string} id id of role to update
     * @param {Object} data Object of role data 
     * @returns 
     */
    updateRole(id, data) {
        const config = { url: `/roles/${id}`, method: 'put', data }
        return api.request(config)
    }

    /**
     * 
     * @param {string} id id of roles
     * @returns 
     */
    deleteRole(id) {
        const config = { url: `/roles/${id}`, method: 'delete' }
        return api.request(config)
    }

}

const roleService = new RoleService()
export default roleService