import { AES,enc } from "crypto-js";
const encKey=process.env.REACT_APP_ENC_KEY||"<>?:{$%^%$~~~~~~~~)(@#$%^&"

const secureLocalStorage={
    setItem:(key,data)=>{
        const stringified=JSON.stringify(data)
        const encData=AES.encrypt(stringified, encKey).toString();
        localStorage.setItem(key,encData)
        localStorage.setItem(`_${key}`,stringified)
    },
    getItem:(key)=>{
        const encrypted=localStorage.getItem(key)
        return encrypted?JSON.parse(AES.decrypt(encrypted, encKey).toString(enc.Utf8)):'';
    },
    removeItem:(key)=>{
        localStorage.removeItem(key)
    },
    clear:()=>{
        localStorage.clear()
    }
}


export default secureLocalStorage


