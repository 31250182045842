import React, { useRef, forwardRef, useMemo, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useMutation, useQuery } from 'react-query';
import { componentService, testSuiteService } from '../../../../services';
import { Controller, useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { suiteActions } from '../../../../store/actions';
const OverrideFieldsModal = ({ show, setShow, step, actionValue, dataset_id }) => {
  const toast = useRef(null);
  const [_step, setStep] = useState(step);
  const suite = useSelector(state => state.suite);
  const dispatch = useDispatch();
  const { data: keywords, isloading } = useQuery({
    queryKey: ['keywords'],
    queryFn: async () => {
      const response = await componentService.getKeywords();
      return response.data;
    },
  });
  const defaultValues = {
    description: '',
    keyword: '',
    xpath: '',
    before_step: '',
    after_step: '',
    expected_output: '',
  };

  useEffect(() => {
    if (step?.override_value && show) {
      stringToObject(step?.override_value);
    }
    setStep(step);
  }, [step, show]);

  //override values from string to object
  function stringToObject(str) {
    const obj = {};
    const pairs = str.split('<=>');

    pairs.forEach(pair => {
      const [key, value] = pair.split(/=(.+)/);
      obj[key] = value;
      setValue(key, value);
    });

    return obj;
  }

  // Convert object to string  key=value<=>key=value overide values

  function objectToString(obj) {
    const pairs = [];

    for (const [key, value] of Object.entries(obj)) {
      if (value != null && value !== '') {
        pairs.push(`${key}=${value}`);
      }
    }

    return pairs.join('<=>');
  }

  const overRideStringConcatenation = values => {
    const hasValue = Object.values(values).some(value => value !== undefined && value !== null && value !== '');

    const _overrideString = Object.entries(values)
      .map(([key, value]) => {
        if (value !== undefined && value !== '' && value !== null) {
          return `${key}=${value}`;
        }
        return ''; // Return an empty string if value is empty
      })
      .filter(pair => pair !== '') // Remove empty strings
      .join('|'); // Join key-value pairs with '||'
    console.log(_overrideString, '_overrideStringConcatenation');

    return _overrideString;
  };
  const overideFunction = async values => {
    const params = { value: values, step_id: _step.id, dataset_id: dataset_id };
    return await testSuiteService.overRideValues(null, params);
  };
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const hasValue = Object.values(watch()).some(value => value !== undefined && value !== null && value !== '');

  const { mutate: onSubmit, isLoading } = useMutation(
    async values => {
      console.log(values, 'values');
      let _values = objectToString(values);
      const response = await overideFunction(_values);
      return { ...response, values: _values };
    },
    {
      onError: error => {
        if (error.response.status === 500) {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Internal Server Error!' });
          return;
        }
        toast.current.show({ severity: 'error', summary: 'Error', detail: error?.response?.data?.message ?? 'Opps! Something went wrong' });
      },
      onSuccess: async res => {
        if (res.success) {
          const _suite = global.structuredClone(suite);
          const activeIndex = _suite.activeIndex;
          const { datasets } = _suite.componentsForTest[activeIndex];
          const dataset = datasets.find(dataset => dataset.id === dataset_id);
          const currentStep = dataset.steps.find(step => step.id === _step.id);
          currentStep.override = true;
          currentStep.override_value = res.values;
          console.log(res.values, 'res.values');
          dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: _suite.componentsForTest });
          toast.current.show({ severity: 'success', summary: 'Alert!', detail: res?.message });
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: res?.message });
        }
      },
    },
  );
  const resetAll = () => {
    reset();
    setShow(false);
  };
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header='Override Component Info'
        visible={show}
        maximizable
        style={{ width: '850px' }}
        onHide={() => {
          resetAll();
        }}
      >
        <h4 className='text-md'>Step ID: {_step?.id}</h4>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-column gap-2'>
            <div className='flex gap-2 align-items-center flex-wrap'>
              <label className='text-sm lg:col-3 col-12'>
                <strong>Action:</strong>
              </label>

              <Controller name='description' control={control} render={({ field }) => <InputText className='p-inputtext-sm flex-1' {...field} value={field.value ?? ''} />} />
            </div>

            <div className='flex gap-2 align-items-center flex-wrap'>
              <label className='text-sm lg:col-3 col-12'>
                <strong>Expected Output:</strong>
              </label>

              <Controller name='expected_output' control={control} render={({ field }) => <InputText className='flex-1' {...field} />} />
            </div>

            <div className='flex gap-2 align-items-center flex-wrap'>
              <label className='text-sm lg:col-3 col-12'>
                <strong>Keyword:</strong>
              </label>
              <Controller
                name='keyword'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    disabled={isloading}
                    className='flex-1'
                    value={value ?? ''}
                    onChange={e => {
                      onChange(e.value);
                    }}
                    filter
                    options={keywords ?? []}
                    optionLabel='name'
                    optionValue='name'
                  />
                )}
              />
            </div>
            {/* <div className="flex gap-2 align-items-center flex-wrap">
                            <label className="text-sm lg:col-3 col-12">
                                <strong>Locator Type:</strong>
                            </label>
                            <Controller
                                name=""
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className="flex-1"
                                        value={value ?? ""}
                                        onChange={(e) => {
                                            onChange(e.value);
                                        }}
                                        filter
                                        options={keywords ?? []}
                                        optionLabel="name"
                                        optionValue="id"
                                        disabled
                                    />
                                )}
                            />
                        </div> */}

            {/* <div className="flex gap-2 align-items-center flex-wrap">
                            <label className="text-sm lg:col-3 col-12">
                                <strong>UFT_BrowserObject:</strong>
                            </label>
                            <Controller name="brpg_obj" control={control} render={({ field }) => <InputText className="flex-1" type="text" {...field} />} />
                        </div> */}

            {/* <div className="flex gap-2 align-items-center flex-wrap">
                            <label className="text-sm lg:col-3 col-12">
                                <strong>UFT_ElementObject:</strong>
                            </label>
                            <Controller name="object_string" control={control} render={({ field }) => <InputText className="flex-1" type="text" {...field} />} />
                        </div> */}

            <div className='flex gap-2 align-items-center flex-wrap'>
              <label className='text-sm lg:col-3 col-12'>
                <strong>Web Locator:</strong>
              </label>
              <Controller name='xpath' control={control} render={({ field }) => <InputText className='flex-1' type='text' {...field} />} />
            </div>

            {/* <div className="flex gap-2 align-items-center flex-wrap">
                            <label className="text-sm lg:col-3 col-12">
                                <strong>Android Locator:</strong>
                            </label>
                            <Controller name="name" control={control} render={({ field }) => <InputText className="flex-1" type="text" {...field} disabled />} />
                        </div> */}
            {/* <div className="flex gap-2 align-items-center flex-wrap">
                            <label className="text-sm lg:col-3 col-12">
                                <strong>IOS Locator:</strong>
                            </label>
                            <Controller name="name" control={control} render={({ field }) => <InputText className="flex-1" type="text" {...field} disabled />} />
                        </div> */}
            {/* <div className="flex gap-2 align-items-center flex-wrap">
                            <label className="text-sm lg:col-3 col-12">
                                <strong>Window Locator:</strong>
                            </label>
                            <Controller name="name" control={control} render={({ field }) => <InputText className="flex-1" type="text" {...field} disabled />} />
                        </div> */}

            <div className='flex gap-2 align-items-center flex-wrap'>
              <label className='text-sm lg:col-3 col-12'>
                <strong>Before Step</strong>
              </label>
              <Controller name='before_step' control={control} render={({ field }) => <InputText className='flex-1' type='text' {...field} />} />
            </div>

            <div className='flex gap-2 align-items-center flex-wrap'>
              <label className='text-sm lg:col-3 col-12'>
                <strong>After Step</strong>
              </label>
              <Controller name='after_step' control={control} render={({ field }) => <InputText className='flex-1' type='text' {...field} />} />
            </div>
          </div>
          <div className='text-right w-full mt-3 position-sticky bottom bg-white'>
            <Button className='small-button' label='Override' type='submit' disabled={!hasValue} />
          </div>
          {false && (
            <>
              <code>
                <pre>Values: {JSON.stringify(watch(), null, 2)}</pre>
              </code>
              <code>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </code>
            </>
          )}
        </form>
      </Dialog>
    </>
  );
};

export default (props, ref) => useMemo(() => <OverrideFieldsModal {...props} ref={ref} />, [props]);
