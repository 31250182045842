import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import secureLocalStorage from './secureLocalStorage';

export const clone = obj => _.cloneDeep(obj);

export const scrollDown = ref => {
  setTimeout(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, 100);
};

const badgeTypeMapping = {
  'Not Started': 'info',
  'In Progress': 'warning',
  Passed: 'success',
  Fixed: 'success',
  Closed: 'success',
  Failed: 'danger',
  Rejected: 'danger',
  Deferred: 'danger',
  unresolved: 'danger',
  'Glitch - Fail': 'danger',
};

export const getBadgeType = status => {
  return badgeTypeMapping[status] || '';
};

export const getUserSettings = () => {
  return secureLocalStorage.getItem('currentUser').settings;
};
export const setUserSettings = settings => {
  const currentUser = secureLocalStorage.getItem('currentUser');
  currentUser.settings = settings;
  secureLocalStorage.setItem('currentUser', currentUser);
};

export const getUserPreferenceSetting = (page, settingName) => {
  try {
    return getUserSettings().preferences[page][settingName];
  } catch (error) {
    return undefined;
  }
};

export const renderNameAndTime = dynamicString => {
  if (!dynamicString) return <></>;
  const splitIndex = dynamicString.indexOf('(');
  const name = dynamicString.substring(0, splitIndex).trim();
  const time = dynamicString.substring(splitIndex).trim();

  return (
    <div className='flex flex-column '>
      <span className='name font-bold'>{name}</span>
      <span className='time'>{time}</span>
    </div>
  );
};


export function generateRandomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
