import api from "../utils/api";
class FolderService {


    /**
     * 
     * @param {object} data Object of folder data 
     * @returns 
     */
    createFolder(data) {
        const config = { url: '/folders', method: 'post', data }
        return api.request(config)
    }
    /**
     * 
     * @param {integer} type type  
     * @param {integer} parent parent folder id
     * @param {AbortController} abortController to abort request on unmount
     * @returns 
     */
    getFolders( parent=null, abortController) {
        const config = {
            url: '/folders',
            params: {
                ...(parent && { parent_id: parent })
            },
            method: 'get',
            ...(abortController && { signal: abortController.signal })
        }
        return api.request(config)
    }
    /**
     * 
     * @param {Array} ids array of ids 
     * @param {AbortController} abortController 
     * @returns 
     */

    deleteBulk(ids,abortController){
        const config={ 
            url: `/folders/bulk-delete`,
            method: 'post',
            params:{ids},
            ...(abortController && { signal: abortController.signal })
            }
        return api.request(config)
    }
}
const folderService = new FolderService()
export default folderService