import './style.scss';
import React, { useCallback, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import { BlockUI } from 'primereact/blockui';
import { useDispatch, useSelector } from 'react-redux';
import { sortableContainer, sortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';
import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { suiteActions } from '../../../../store/actions';
import { clone, scrollDown } from '../../../../utils/common';
import OverrideFieldsModal from './OverrideFieldsModal';
import { useMutation } from 'react-query';
import { testSuiteService } from '../../../../services';
import { Divider } from 'primereact/divider';
const crypto = require('crypto');

const DatasetWidget = ({ formSubmit, isSubmitting }) => {
  const tableRef = useRef(null);
  const msgs = useRef(null);
  const dispatch = useDispatch();
  const { componentsForTest, activeIndex } = useSelector(state => state.suite);
  const activeComponent = componentsForTest[activeIndex];
  const { steps } = activeComponent;

  const addDataset = () => {
    const datasetObject = {
      scenario: '',
      randomIdForKey: crypto.randomBytes(20).toString('hex'),
      checked: false,
      status: false,
      steps: steps.map(step => {
        return {
          ...step,
          display_id: step.display_id,
          id: step.id,
          value: 'skip',
          randomIdForKey: crypto.randomBytes(20).toString('hex'),
        };
      }),
    };
    // const prev=JSON.parse(JSON.stringify(componentsForTest))
    const prev = clone(componentsForTest);
    prev[activeIndex].datasets.push(datasetObject);
    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
    //  setDatasetList((prev)=>[...prev,datasetObject])

    scrollDown(tableRef);
  };
  const removeDataset = () => {
    const prev = cloneDeep(componentsForTest);
    const datasets = prev[activeIndex].datasets;

    const selectedDatasets = datasets.filter(ds => ds.checked);

    if (selectedDatasets.length === datasets.length) {
      msgs.current.show({ sticky: true, life: 4000, severity: 'error', summary: 'Error: ', detail: 'You cannot delete all the datasets. You must have at least one dataset.' });
      // alert("You cannot delete all the datasets. You must have at least one dataset.");
      return;
    }

    prev[activeIndex].datasets = datasets.filter(ds => !ds.checked);
    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
  };
  const copyDataset = () => {
    const prev = cloneDeep(componentsForTest);
    const filtered = prev[activeIndex].datasets.filter(ds => ds.checked);
    const selected = filtered.map(({ scenario, status, steps }) => {
      return {
        scenario: scenario,
        randomIdForKey: crypto.randomBytes(20).toString('hex'),
        checked: false,
        status: status,
        steps: steps.map(step => {
          return {
            ...step,
            display_id: step.display_id,
            id: step.id,
            value: step.value,
            randomIdForKey: crypto.randomBytes(20).toString('hex'),
          };
        }),
      };
    });
    prev[activeIndex].datasets = [...prev[activeIndex].datasets, ...selected];
    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
    scrollDown(tableRef);
  };

  const handleDatasetInputChange = (e, datasetListIndex, stepIndex, override = false) => {
    const prev = cloneDeep(componentsForTest);
    console.log(prev[activeIndex].datasets);
    if (override) {
      prev[activeIndex].datasets[datasetListIndex].steps[stepIndex].override = e.target.value;
    } else {
      prev[activeIndex].datasets[datasetListIndex].steps[stepIndex].value = e.target.value;
    }
    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
  };
  const handleUpdateparameterValue = useCallback(
    (e, datasetListIndex, stepIndex, override = false) => {
      const prev = cloneDeep(componentsForTest);
      console.log(prev[activeIndex].datasets, 'update parameter value');
      const dataset = prev[activeIndex].datasets[datasetListIndex];
      const _currentStep = dataset.steps[stepIndex];
      _currentStep.value = e.target.value ?? '';
      dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
    },
    [componentsForTest, activeIndex, dispatch],
  );

  const handleScenarioChange = (e, datasetListIndex) => {
    const prev = cloneDeep(componentsForTest);
    prev[activeIndex].datasets[datasetListIndex].scenario = e.target.value;
    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
  };

  const handleStatusChange = (e, datasetListIndex) => {
    const prev = cloneDeep(componentsForTest);
    prev[activeIndex].datasets[datasetListIndex].status = e.value;
    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
  };

  const selectDataset = (e, datasetListIndex) => {
    const prev = cloneDeep(componentsForTest);
    const datasets = prev[activeIndex].datasets;

    if (e.target.checked && datasetListIndex === undefined) {
      console.log('all');
      datasets.forEach(dataset => {
        dataset.checked = true;
      });
    } else if (datasetListIndex !== null && datasets[datasetListIndex]) {
      console.log('single');
      datasets[datasetListIndex].checked = e.target.checked;
    } else {
      datasets.forEach(dataset => {
        console.log('all unselect');
        dataset.checked = false;
      });
    }

    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
  };

  return (
    <div>
      <div className='p-3 bg-blue-900 border-round text-white mb-1 mt-2 flex align-items-center '>
        <span className='flex align-items-center gap-3'>
          <input type={'checkbox'} onChange={e => selectDataset(e)} />
          <span className='pi pi-plus cursor-pointer' onClick={() => addDataset()} style={{ fontSize: '18px' }}></span>
          <Divider layout="vertical" className='p-0 m-0'/> 
          <span className='pi pi-copy cursor-pointer' onClick={() => copyDataset()} style={{ fontSize: '18px' }}></span>
          <Divider layout="vertical" className='p-0 m-0'/> 
          <span className='pi pi-trash cursor-pointer ' onClick={() => removeDataset()} style={{ fontSize: '18px' }}></span>
          <Divider layout="vertical" className='p-0 m-0' /> 
          <span  disabled={isSubmitting} onClick={() => isSubmitting ? null : formSubmit?.()} style={{ fontSize: '18px' }}  className={`pi ${isSubmitting ? "p-icon-spin pi-spinner" :  "pi-save"} cursor-pointer `}></span>
        </span>
        <Messages
          pt={{
            root: { className: 'my-0 mx-auto' },
            wrapper: { className: ' p-1 ' },
          }}
          className='m-0'
          ref={msgs}
        />
      </div>

      <table ref={tableRef} className={'dataset-table '} style={{ maxHeight: '19vw' }}>
        <TableHead steps={steps} />
        <TableBody activeIndex={activeIndex} handleDatasetInputChange={handleDatasetInputChange} parameterValueUpdate={handleUpdateparameterValue} handleScenarioChange={handleScenarioChange} handleStatusChange={handleStatusChange} selectDataset={selectDataset} />
      </table>
    </div>
  );
};

const TableHead = ({ steps }) => {
  return (
    <thead>
      <tr>
        <th>
          <span></span>
        </th>
        <th className='sticky left-0 '>
          <span>Status & Scenario</span>
        </th>
        {steps.map((step, i) => (
          <th key={step?.id}>{i + 1}</th>
        ))}
      </tr>
    </thead>
  );
};

const DragHandle = sortableHandle(() => <i className='pi pi-align-justify '></i>);

const SortableContainer = sortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = sortableElement(({ value }) => {
  const [showDatasetTableModal, setShowDatasetTableModal] = useState(false);
  const [showOverrideFieldsModal, setShowOverrideFieldsModal] = useState(false);
  const suite = useSelector(state => state.suite);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(false);
  const { set, datasetListIndex, handleScenarioChange, handleDatasetInputChange, handleStatusChange, selectDataset, parameterValueUpdate } = value;

  const hideDatasetTableModal = () => {
    setShowDatasetTableModal(false);
  };

  const getOverrides = useCallback((step, key) => {
    const value = step?.override_value ?? undefined;
    let _value = null;
    if (value) {
      const keyValuePairs = value.split('||');
      keyValuePairs.forEach(pair => {
        const [pairKey, pairValue] = pair.split('=');
        if (pairKey.trim() === key.trim()) {
          _value = pairValue ?? '';
        }
      });
    }
    return _value;
  }, []);

  const overideFunction = useCallback(
    async step => {
      const params = { reset: true, step_id: step.id, dataset_id: set?.id };
      return await testSuiteService.overRideValues(null, params);
    },
    [set?.id],
  );

  const { mutate: onOverrideReset } = useMutation(
    async values => {
      let _values = values;
      const response = await overideFunction(_values);
      return { ...response, step: _values };
    },
    {
      onError: error => {
        if (error.response.status === 500) {
          alert('Internal Server Error!');
          return;
        }
        alert(error?.response?.data?.message ?? 'Oops! Something went wrong');
      },
      onSuccess: async res => {
        if (res.success) {
          const _suite = global.structuredClone(suite);
          const activeIndex = _suite.activeIndex;
          const { datasets } = _suite.componentsForTest[activeIndex];
          const dataset = datasets.find(dataset => dataset.id === set?.id);
          const _currentStep = dataset.steps.find(_step => _step.id === res?.step?.id);
          _currentStep.override = false;
          _currentStep.override_value = null;
          dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: _suite.componentsForTest });
        } else {
          alert(res?.message);
        }
      },
    },
  );
  const handleOverrideButtonClick = useCallback(
    step => {
      if (set.id) {
        setShowOverrideFieldsModal(true);
        setCurrentStep(step);
      }
    },
    [set.id],
  );

  const handleOverrideReset = useCallback(
    step => {
      setCurrentStep(step);
      onOverrideReset(step);
    },
    [onOverrideReset],
  );

  const borderRight = { borderRight: '2px solid #dee2e6' };

  return (
    <tr key={set.randomIdForKey}>
      <td className='text-center'>
        <DragHandle />
      </td>
      <td className={'flex align-items-center sticky left-0 bg-white '}>
        {/* Checkbox */}
        <div style={borderRight} className={'pr-2 mr-2 ml-2'}>
          <input type={'checkbox'} checked={set.checked} key={set.randomIdForKey + new Date().getTime()} onChange={e => selectDataset(e, datasetListIndex)} />
        </div>
        {/* Status Switch */}
        <div style={borderRight} className={'pr-2 '}>
          <InputSwitch checked={!!set.status} onChange={e => handleStatusChange(e, datasetListIndex)} />
        </div>
        {/* Override Fields Modal */}
        <div style={borderRight} className={'pr-2 ml-2'}>
          <OverrideFieldsModal dataset_id={set.id} step={currentStep} show={showOverrideFieldsModal} setShow={setShowOverrideFieldsModal} actionValue={GetTooltipText(currentStep ?? '')} />
          {/* Button to open Dataset Table Modal */}
          <Button icon='pi pi-table' onClick={() => setShowDatasetTableModal(true)} severity='info' className='table-button' tooltip='Open In Table' />
          {/* Dataset Table Modal */}
          <Dialog
            visible={showDatasetTableModal}
            maximizable
            style={{ width: '1000px' }}
            header={
              <div className='flex align-items-center'>
                <p className='my-0'>Steps & Values</p>
                {!set.id && <Message severity='error' text='Update / Save the dataset to override these steps' className=' ml-8' />}
              </div>
            }
            modal
            className='p-fluid'
            onHide={hideDatasetTableModal}
            footer={() => <></>}
          >
            <BlockUI blocked={!set.id} className=' -m-3' style={{ marginLeft: '-8xp' }}>
              {/* Steps & Values Grid */}
              <div className='grid mb-2 pt-3' style={{ borderBottom: '2px solid #dee2e6' }}>
                {/* Headers */}
                <div className='col-4 font-bold'>Step Action:</div>
                <div className='col-3 font-bold'>Expected Output:</div>
                <div className='col-3 font-bold'>Parameter Value:</div>
                <div className='col-2 font-bold'>Component Info:</div>
              </div>
              {/* Steps Mapping */}
              {set.steps.map((step, stepIndex) => (
                <div key={step.id} className={`grid row-gap-2 ${stepIndex === 0 ? 'mt-1' : ''}`}>
                  {/* Step Action */}
                  <div className='col-4 relative border_less_input'>
                    <Badge value={stepIndex + 1} className='surface-ground border-none text-color '></Badge>
                    <InputText value={GetTooltipText(step)} />
                  </div>
                  {/* Expected Output */}
                  <div className='col-3 border_less_input'>
                    <InputText value={getOverrides(step, 'expected_output')} />
                  </div>
                  {/* Parameter Value */}
                  <div className='col-3 border_less_input'>
                    <InputText value={step.value} defaultValue={step.value} onChange={e => parameterValueUpdate(e, datasetListIndex, stepIndex)} />
                  </div>
                  {/* Override Button */}
                  <div className={`col-2 ${step?.override ? ' p-buttonset' : ''}`}>
                    <Button
                      label='Override'
                      size='small'
                      onClick={
                        set.id
                          ? () => {
                              handleOverrideButtonClick(step);
                            }
                          : () => ''
                      }
                      disabled={!set?.id}
                      className={`${step?.override ? 'bg-primary' : 'surface-ground text-color'} override-button border-none `}
                    />
                    {step?.override && (
                      <Button
                        size='small'
                        icon='pi pi-refresh'
                        severity='danger'
                        className='override-button'
                        onClick={() => {
                          handleOverrideReset(step);
                        }}
                      ></Button>
                    )}
                  </div>
                </div>
              ))}
            </BlockUI>
          </Dialog>
        </div>
        {/* Scenario Description Input */}
        <InputText className={'ml-2 h-full'} onChange={e => handleScenarioChange(e, datasetListIndex)} value={set.scenario} tooltip={set.scenario} placeholder='Scenario Description' style={{ width: '60rem' }} />
      </td>
      {/* Step Values */}
      {set.steps.map((step, stepIndex) => (
        <td key={step.randomIdForKey}>
          <InputText className={'h-full'} value={step.value} defaultValue={step.value} tooltip={GetTooltipText(step)} tooltipOptions={{ position: 'top' }} onChange={e => handleDatasetInputChange(e, datasetListIndex, stepIndex)} />
        </td>
      ))}
    </tr>
  );
});

const TableBody = ({ handleDatasetInputChange, activeIndex, handleScenarioChange, handleStatusChange, selectDataset, parameterValueUpdate }) => {
  const { componentsForTest } = useSelector(state => state.suite);
  const { datasets } = componentsForTest[activeIndex];
  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const prev = clone(componentsForTest);
    prev[activeIndex].datasets = arrayMove(datasets, oldIndex, newIndex);
    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
  };

  return (
    <SortableContainer onSortEnd={onSortEnd} pressDelay={200}>
      {datasets.map((set, datasetListIndex) => {
        return (
          <SortableItem
            key={set.randomIdForKey}
            index={datasetListIndex}
            value={{
              set,
              datasetListIndex,
              handleScenarioChange,
              handleDatasetInputChange,
              handleStatusChange,
              selectDataset,
              parameterValueUpdate,
            }}
          />
        );
      })}
    </SortableContainer>
  );
};

const GetTooltipText = setsStep => {
  const { componentsForTest, activeIndex } = useSelector(state => state.suite);
  const { steps } = componentsForTest[activeIndex];
  if (setsStep) {
    // console.log(setsStep )

    const tooltip =
      steps.filter(step => {
        return setsStep.id === step.id;
      })[0].description || '';

    return tooltip;
  }
};

export default DatasetWidget;
