import React, { useState, useEffect, useRef } from 'react';

import { testPlanService, testSuiteService, userService } from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { getBadgeType } from '../../../utils/common';
import { Dialog } from 'primereact/dialog';
import Toggleable from './Fieldset';
import AlertMsg from '../../../components/common/AlertMsg/AlertMsg';
import StepVideoUpload from '../TestPlanDetails/DetailTabs/StepVideoUpload';
import LongStringWithSeeMore from '../../../utils/LongStringWithSeeMore';
import CreateDefect from './CreateDefect';
import { useQueries, useQuery } from 'react-query';
import { Dropdown } from 'primereact/dropdown';
import CreateGlitch from './CreateGlitch';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
export default function TestPlanLogs() {
  const [visible, setVisible] = React.useState(false);
  const [glitchModalVisible, setGlitchModalVisible] = useState(false);
  const navigate = useNavigate();
  const selectedItemIndex = useRef(null);

  const intialFilters = {
    test_plan: { value: null },
    test_suite: { value: null },
    test_plan_item: { value: null },
    status: { value: null },
    run_by: { value: null },
  };
  const [filterObj, setFilterObj] = useState(intialFilters);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    page: 0,
    limit: 10,
    total: 0,
    sortField: null,
    sortOrder: null,
  });

  const [dataRows, setDataRows] = useState([]);
  const [stepsDetailsModal, setStepsDetailsModal] = useState(false);
  const [testRunnerItem, setTestRunnerItem] = useState({});
  // Function to filter out properties with null values
  function filterNonNullValues(obj) {
    const filteredObj = {};

    for (const key in obj) {
      if (obj[key].value !== '' && obj[key].value !== null) {
        filteredObj[key] = obj[key].value;
      }
    }

    return filteredObj;
  }
  function calculatePageSize(itemHeight, pageHeight) {
    const itemsPerPage = Math.floor(pageHeight / itemHeight);
    return itemsPerPage;
  }

  const { isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['getTestRunnerLogsItems', { lazyParams, filterObj }],
    queryFn: async () => {
      const { limit, page } = lazyParams;
      const itemHeight = 63;
      const pageHeight = window.innerHeight;
      const pageSize = calculatePageSize(itemHeight, pageHeight);
      const filters = filterNonNullValues(filterObj);
      const res = await testPlanService.getTestRunnerLogsItems(null, { limit: pageSize, page: page + 1, ...filters });
      return res.data;
    },
    onSuccess: res => {
      const { total, per_page } = res.meta;
      console.log(total, per_page, 'total , per_page');
      setLazyParams(prev => ({ ...prev, limit: per_page, total }));
      // setTotalRecords(res.meta.total)
      setDataRows(res.data);
      return res.data;
    },
    onError: err => {
      console.log(err);
    },
  });

  const onPage = event => {
    setLazyParams(event);
  };

  // const onRowClick = ({ data, index, orignalEvent }) => {
  //   navigate(`${data.id}`, { state: data })
  // }

  const actionButtonTemplate = (data, { rowIndex }) => {
    const { CAN_CREATE_DEFECT } = data;
    const icon = `pi ${CAN_CREATE_DEFECT ? 'pi-file-edit' : 'pi-exclamation-circle'}`;
    const tooltip = `${CAN_CREATE_DEFECT ? 'Create' : 'View'} Defect for this log `;

    const handleDefect = () => {
      if (CAN_CREATE_DEFECT) {
        setTestRunnerItem(data);
        setVisible(true);
      } else {
        setTestRunnerItem(data);
        setVisible(true);
      }
    };
    return (
      <span clas className={data.status_name === 'Failed' && 'p-buttonset flex '}>
        <Button
          icon='pi pi-eye'
          rounded
          className='action-button'
          tooltipOptions={{ position: 'left' }}
          severity={'info'}
          size='small'
          raised
          tooltip='View Steps Detail'
          onClick={() => {
            selectedItemIndex.current = rowIndex;
            setStepsDetailsModal(true);
          }}
        />
        {data.status_name === 'Failed' && <Button rounded className='action-button' tooltipOptions={{ position: 'left' }} onClick={handleDefect} size='small' raised severity='danger' icon={icon} tooltip={tooltip} />}
      </span>
    );
  };
  const statusTemplate = row => {
    const { status_name, comment } = row;
    const revertStatus = () => {
      if (status_name === 'Failed') {
        setTestRunnerItem(row);
        setGlitchModalVisible(true);
      }
    };
    return (
      <div className='flex gap-2 align-items-center'>
        <Badge className={status_name === 'Failed' ? ' cursor-pointer' : ''} value={status_name} onClick={revertStatus} severity={getBadgeType(status_name)}></Badge>
        {status_name === 'Glitch - Fail' && (
          <Button icon=' pi pi-exclamation-circle text-xl ' className='p-0' text tooltip={comment} data-pr-position='bottom'>
            <i className=' '></i>
          </Button>
        )}
      </div>
    );
  };
  const modalHeader = () => {
    if (!dataRows[selectedItemIndex.current]) {
      return 'Steps';
    }
    const videos = JSON.parse(dataRows[selectedItemIndex.current]?.videos);
    return (
      <div className='flex align-items-center'>
        <h5 className='m-0'>
          {'Steps of '} <LongStringWithSeeMore maxLength={37} content={dataRows[selectedItemIndex.current]?.test_suite_name ?? ""} />
        </h5>
        <div className='ml-auto mr-5'>{videos?.length > 0 && <StepVideoUpload data={videos} />}</div>
      </div>
    );
  };

  const hideStepDetailsModal = () => {
    selectedItemIndex.current = null;
    setStepsDetailsModal(false);
  };

  const [plansQuery, plansitems, suits, users, statuses] = useQueries([
    {
      queryKey: ['plans', 1],
      queryFn: async () => {
        const res = await testPlanService.getTestPlans();
        return res.data;
      },
      enabled: true,
    },

    {
      queryKey: ['plansitem', 2],
      queryFn: async () => {
        const res = await testPlanService.getTestPlansItems();
        return res.data;
      },
      enabled: true,
    },
    {
      queryKey: ['suits', 3],
      queryFn: async () => {
        const res = await testSuiteService.getTestSuites();
        return res.data;
      },
      enabled: true,
    },
    {
      queryKey: ['users', 4],
      queryFn: async () => {
        const res = await userService.getUsers();
        return res.data;
      },
      enabled: true,
    },
    {
      queryKey: ['status', 5],
      queryFn: async () => {
        const res = await testPlanService.getTestRunnerStatuses();
        return res.data;
      },
      enabled: true,
    },
  ]);

  const columns = [
    {
      field: 'test_plan_name',
      header: 'Test Plan',
      filterElement: options => filtersDropDown(options, plansQuery.data, 'Filter by plan'),
      filterField: 'test_plan',
    },
    {
      field: 'test_plan_item_name',
      header: 'Test Suite',
      filterElement: options => filtersDropDown(options, plansitems.data, 'Filter by Plan Item'),
      filterField: 'test_plan_item',
      filerPlaceholder: 'hello',
    },
    {
      field: rowData => <LongStringWithSeeMore classes={'max-w-30rem '} content={rowData.test_suite_name} maxLength={50} />,
      header: 'Test Case Title',
      filterElement: options => suitFiltersDropDown(options, suits.data, 'Filter by Suite'),
      filterField: 'test_suite',
    },
    { field: 'username', header: 'Run By', filterElement: options => filtersDropDown(options, users.data, 'Filter by User'), filterField: 'run_by' },
    { field: statusTemplate, header: 'Satus', filterElement: options => filtersDropDown(options, statuses.data, 'Filter by Status', true), filterField: 'status' },
    { field: 'created_at', body: ({ created_at }) => moment(created_at ?? new Date()).format('DD-MM-YYYY HH:MM A'), header: 'Run Date', filterElement: (options, data) => dateFilters(options, data), filterField: 'created_at' },
    { header: 'Actions', body: actionButtonTemplate, filterElement: <Button icon='pi pi-filter-slash' raised outlined size='small' onClick={() => setFilterObj(intialFilters)} /> },
  ];

  const selectedStatusTemplate = (option, props) => {
    if (option) {
      return <Badge value={option.name} severity={getBadgeType(option.name)}></Badge>;
    }

    return <span>{props.placeholder}</span>;
  };

  const statusOptionTemplate = option => {
    return <Badge value={option.name} severity={getBadgeType(option.name)}></Badge>;
  };

  const selectedSuiteTemplate = (option, props) => {
    if (option) {
      return (
        <div>
          <LongStringWithSeeMore maxLength={15} content={option.title} hideSeeMore={true} />
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const suitOptionTemplate = option => {
    return <LongStringWithSeeMore maxLength={40} content={option.title} hideSeeMore={true} />;
  };

  const filtersDropDown = (options, data, placeholder, template) => {
    return (
      <Dropdown
        showClear
        c
        filter
        label='test'
        placeholder={placeholder}
        options={data ?? []}
        value={filterObj[options.field]?.value}
        optionLabel='name'
        optionValue='id'
        onChange={e => options.filterApplyCallback(e.value)}
        itemTemplate={template ? statusOptionTemplate : null}
        valueTemplate={template ? selectedStatusTemplate : null}
      />
    );
  };
  const dateFilters = (options, data, placeholder, template) => {
    console.log(options, data, 'filters options');
    return (
      <Calendar value={filterObj[options.field]?.value} onChange={e => options.filterApplyCallback(e.value)} placeholder='Filter by run date' />
      // <Dropdown
      //     showClear
      //     c
      //     filter
      //     label="test"
      //     placeholder={placeholder}
      //     options={data ?? []}
      //     value={filterObj[options.field]?.value}
      //     optionLabel="name"
      //     optionValue="id"
      //     onChange={(e) => options.filterApplyCallback(e.value)}
      //     itemTemplate={template ? statusOptionTemplate : null}
      //     valueTemplate={template ? selectedStatusTemplate : null}
      // />
    );
  };
  const suitFiltersDropDown = (options, data, placeholder, template) => {
    return (
      <Dropdown
        showClear
        className='max-w-full'
        filter
        label='test'
        placeholder={placeholder}
        options={data ?? []}
        value={filterObj[options.field]?.value}
        optionLabel='title'
        optionValue='id'
        onChange={e => options.filterApplyCallback(e.value)}
        itemTemplate={suitOptionTemplate}
        valueTemplate={selectedSuiteTemplate}
      />
    );
  };
  const onFilter = e => {
    console.log(e.filters);
    setFilterObj(e.filters);
  };

  return (
    <div>
      <CreateDefect setVisible={setVisible} visible={visible} testRunnerItem={testRunnerItem} refetchLogs={refetch} />
      <CreateGlitch setVisible={setGlitchModalVisible} visible={glitchModalVisible} testRunnerItem={testRunnerItem} refetchLogs={refetch} />
      <DataTable
        paginator
        first={lazyParams.first}
        rows={lazyParams.limit}
        totalRecords={lazyParams.total}
        onPage={onPage}
        lazy
        onFilter={onFilter}
        filterDisplay='row'
        value={dataRows ?? []}
        dataKey='id'
        loading={isLoading || isRefetching}
        className='datatable-responsive'
        emptyMessage='No Data.'
        responsiveLayout='scroll'
      >
        {columns.map(col => (
          <Column filter showFilterMenu={false} {...col}></Column>
        ))}
      </DataTable>
      <Dialog visible={stepsDetailsModal} maximizable style={{ width: '70%' }} header={modalHeader} modal className='p-fluid' onHide={hideStepDetailsModal}>
        {selectedItemIndex.current !== null && dataRows[selectedItemIndex.current]?.added_steps.length > 0 ? (
          <div className='p-dialog-content p-0'>
            {dataRows[selectedItemIndex.current]?.added_steps.map((step, i) => {
              return <Toggleable data={step} index={i} />;
            })}
          </div>
        ) : (
          <AlertMsg>
            <div className='ml-2'>No Steps.</div>
          </AlertMsg>
        )}
      </Dialog>
    </div>
  );
}
