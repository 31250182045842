import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Dialog } from 'primereact/dialog';

import { useSelector } from "react-redux";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { testPlanService } from '../../../services';
import LoaderButton from '../../../components/common/loaderButton/LoaderButton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AlertMsg from '../../../components/common/AlertMsg/AlertMsg';
import AlertDialog from '../../../components/common/AlertDialog/AlertDialog';
import { Toast } from 'primereact/toast';
export default function AddTestCase({ addCaseSuiteCallback, selectedRows, removeTestPlanItemCallback }) {

  useEffect(() => {
    return () => {

    }
  }, [])


  const { details, activeItemIndex } = useSelector((state) => state.testPlan)
  const selectedItem = details.items[activeItemIndex]

  const [addTestCaseModal, setAddTestCaseModal] = useState(false)
  const [testcases, setTestcases] = useState([])
  const [testcasesLoading, setTestcasesLoading] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)



  const hideTestCaseModal = useCallback(() => {
    setAddTestCaseModal(false)
    resetToInitial()
  }, [])
  const [t, setT] = useState(null);

  const [sugesstionLoading, setSuggestionLoading] = useState(false)
  const [areaPathSuggestions, setAreaPathSuggestions] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const areaPathParent = useRef(null)
  const allSelectedPath = useRef([])
  const areaPathInp = useRef(null)
  const selectCasesRef = useRef([]);
  const toast = useRef()

  const onChangeAreaPath = (e) => {

    const splittedPaths = e.target.value.split('/')
    if (splittedPaths[splittedPaths.length - 1] === '') {
      splittedPaths.pop()
    }

    if (splittedPaths.length === 0) {
      areaPathParent.current = null
      allSelectedPath.current = []
      setAreaPathSuggestions([])
    } else {
      allSelectedPath.current.forEach(p => {
        if (p.name === splittedPaths[splittedPaths.length - 1]) {
          areaPathParent.current = p.id
        }
      });
    }


    let query = { query: e.target.value }

    if (areaPathParent.current) {
      query = { parent_id: areaPathParent.current }
    }

    setSuggestionLoading(true)


    if (t) clearTimeout(t);
    setT(setTimeout(() => {
      testPlanService.getAreaPathSuggestions(query).then((res) => {
        setAreaPathSuggestions(res.data)
        console.log(res)
      }).catch((err) => {
        console.log(err)
      }).finally(() => setSuggestionLoading(false))
    }, 750));
  }

  const onSelectAreaPath = (data) => {
    areaPathParent.current = data.id
    const prev = areaPathInp.current.value
    allSelectedPath.current.push(data)
    areaPathInp.current.value = prev.includes('/') ? prev + data.name : data.name
    setAreaPathSuggestions([])
  }

  const getTestCases = () => {
    const controller = new AbortController()
    const folder_id = areaPathParent.current
    setTestcasesLoading(true)
    testPlanService.getTestCases({ folder_id, test_plan_item_id: selectedItem.id }, controller).then((res) => {
      setTestcases(res.data)
    }).catch((err) => {
      console.log(err)
    }).finally(() => setTestcasesLoading(false))
  }

  const addCases = () => {
    setSubmitting(true)
    const selectedIds = selectCasesRef.current.filter((_case) => _case.checked).map((_case) => _case.id)
    const payload = { test_plan_item_id: selectedItem.id, test_design_ids: selectedIds }
    testPlanService.addTestCasesinSuite(payload).then((res) => {
      resetToInitial()
      setAddTestCaseModal(false)
      addCaseSuiteCallback()
    }).catch(() => {

    }).finally(() => setSubmitting(false))
  }

  const resetToInitial = useCallback(() => {
    areaPathParent.current = null
    areaPathInp.current = null
    selectCasesRef.current = [];
    setTestcases([])
    setAreaPathSuggestions([])
  }, [])

  const selectTemplate = (row, { rowIndex }) => {
    return <input type={'checkbox'} id={row.id} ref={(el) => selectCasesRef.current[rowIndex] = el} />
  }

  const onDeleteAlertHide = () => {
    setShowDeleteAlert(false)
    //  clearDeletionSeletions() 

  }

  const onDeleteTestPlanItems = () => {
    //api call running. 
    testPlanService.deletePlanItem(null, selectedRows)
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'Deleted', detail: 'Test Plan Item has been deleted successfully' });
        onDeleteAlertHide()
        if (removeTestPlanItemCallback) {
          removeTestPlanItemCallback()
        }
      }).catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error- Could not delete', detail: 'Something went wrong.' });
      })
  }
  return (
    <div>
      <Dialog visible={addTestCaseModal} maximizable style={{ width: '1000px' }} header="Add Test Case To Suite" modal className="p-fluid" onHide={hideTestCaseModal} >

        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-5">
            <label htmlFor="name">Fields</label>
            <select className='p-inputtext p-component'>
              <option value='areaPath'>Area Path</option>
            </select>
          </div>
          <div className="field col-12 md:col-2">
            <label htmlFor="name">Operator</label>
            <select className='p-inputtext p-component'>
              <option value='='>=</option>
            </select>
          </div>
          <div className="field col-12 md:col-5">
            <label htmlFor="name">Value</label>
            <span className="p-input-icon-right">
              {sugesstionLoading && <i className="loader" style={{ 'borderTopColor': '#6c757d' }} />}



              <InputText
                ref={areaPathInp}
                autoComplete='off'
                name='area_path'
                type="text"
                onChange={(e) => { onChangeAreaPath(e) }} />
            </span>
            {
              areaPathSuggestions.length > 0 &&

              <div className='ml-2 p-listbox p-component ' >
                <div className='p-listbox-list-wrapper '  >
                  <ul className="p-listbox-list">

                    {
                      areaPathSuggestions.map((data) => {
                        return <li className={`p-listbox-item`} onClick={() => onSelectAreaPath(data)} >{data.name}</li>
                      })
                    }
                  </ul>
                </div>
              </div>
            }
          </div>
          <div className="field col-12 md:col-5">
            <select className='p-inputtext p-component'>
              <option value='id'>ID</option>
              <option value='name'>Name</option>
            </select>
          </div>
          <div className="field col-12 md:col-2">
            <select className='p-inputtext p-component'>
              <option value='='>=</option>
            </select>
          </div>
          <div className="field col-12 md:col-5">
            <InputText />
          </div>
        </div>
        <div className='pt-4 pb-4 '>
          <LoaderButton onClick={getTestCases} keepText loading={testcasesLoading}
            type='button' icon="pi pi-play" className="p-button-sm w-10rem">
            Run Query
          </LoaderButton>

        </div>

        {testcases.length === 0 && !testcasesLoading && <AlertMsg><div className="ml-2">No Test cases. Please change filter above</div></AlertMsg>}

        {
          testcases.length > 0 &&

          <div>
            <DataTable value={testcases} responsiveLayout="scroll">
              <Column field="id" header="*" body={selectTemplate} ></Column>
              <Column field="title" header="Title"></Column>
              <Column field="state.name" header="Status"></Column>
            </DataTable>
            <LoaderButton
              keepText
              loading={submitting}
              onClick={addCases}
              style={{ width: '9rem' }}
              className='p-button-sm mt-2'>
              Add Cases
            </LoaderButton>

          </div>

        }
      </Dialog>
      <Toast ref={toast} />
      <AlertDialog
        message={'Are you sure you want to delete this test plan item?'}
        show={showDeleteAlert}
        onYes={onDeleteTestPlanItems}
        onNo={onDeleteAlertHide}
        onHide={onDeleteAlertHide}
      />
      <div className='flex justify-content-end p-buttonset mb-1'>
        <Button icon='pi pi-plus' className='p-button-sm' tooltip='Add Test Case' tooltipOptions={{ position: "left" }}
          onClick={() => setAddTestCaseModal(true)} />
        <Button icon='pi pi-trash' className='p-button-sm p-button-danger' tooltip='Delete Selected Test Case' tooltipOptions={{ position: "left" }}
          onClick={() => setShowDeleteAlert(true)} />
      </div>

    </div>
  )
}
