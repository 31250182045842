import api from "../utils/api";
class TestPlanService {


    /**
    *
    * @param {AbortController} abortController to abort request on unmount
    * @param {Object} Object of query params
    * @returns
    */
    getTestPlans(abortController, params = null) {
        const config = {
            url: '/test-plans',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }


    /**
     *
     * @param {object} data Object of test plan data
     * @returns
     */
    createTestPlan(data) {
        const config = { url: '/test-plans', method: 'post', data }
        return api.request(config)
    }

    /**
     * 
     * @param {*} only one param query or parent_id is used 
     * @param {*} abortController 
     * @returns 
     */
    getAreaPathSuggestions({ query = null, parent_id = null }, abortController) {
        const config = {
            url: '/folders-path',
            method: 'get',
            params: {
                ...(query && { query: query }),
                ...(parent_id && { parent_id: parent_id }),
            },
            ...(abortController && { signal: abortController.signal })
        }
        return api.request(config)
    }

    /**
   *
   * @param {string} id id of testplan
   * @param {AbortController} abortController to abort request on unmount
   *
   * @returns
   */
    getTestPlan(id, abortController) {

        const config = {
            url: `/test-plans/${id}`,
            method: 'get', ...(abortController && { signal: abortController.signal }),

        }
        return api.request(config)
    }

    /**
     * 
     * @param {*} name of the item
     * @param {AbortController} abortController to abort request on unmount
     * @returns 
     */
    addItem(data, abortController) {
        const config = {
            url: `/test-plans-items`,
            method: 'post',
            data,
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }

    /**
     * 
     * @param {*} Query param Object of test_suite_column,operator,test_suite_value,folder_id
     * @param {*} abortController 
     * @returns 
     */

    getTestCases({ test_suite_column, operator, test_suite_value, folder_id, test_plan_item_id }, abortController) {
        const config = {
            url: `/folders/testsuite`,
            method: 'get',
            params: {
                test_suite_column,
                operator,
                test_suite_value,
                folder_id,
                test_plan_item_id
            },
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }

    /**
     * 
     * @param {*} data 
     * @param {*} abortController 
     * @returns 
     */
    addTestCasesinSuite(data, abortController) {
        const config = {
            url: `/add/testsuites/against/testplanitems`,
            method: 'post',
            data,
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }

    /**
     * 
     * @param {*} id of the test suite item
     * @param {*} abortController 
     * @returns 
     */

    getTestSuiteItemsFromItem(id, abortController) {
        const config = {
            url: `/get/testsuites/against/testplanitems/${id}`,
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }

    /**
     * 
     * @param {*} data object having test_plan_item_id and test_suites[]
     * @param {*} abortController 
     * @returns 
     */
    getTestSuitsSteps(data, abortController) {
        const config = {
            url: '/get/testsuites/steps',
            method: 'post',
            data,
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }

    /**
 * 
 * @param {*} data object having test_plan_item_id and test_suites[]
 * @param {*} abortController 
 * @returns 
 */
    changeTestToNotStarted(data, abortController) {
        const config = {
            url: '/update/testsuites/status/not-started',
            method: 'post',
            data,
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }
    /**
     * 
     * @param {*} data object having test_plan_item_id and test_suites[] 
     * @returns 
     */
    getTestSuitsStepsForLocal(data) {

        const config = {
            url: '/automation/get/testsuites/steps',
            method: 'post',
            data,
        }
        return api.request(config)
    }
    /**
     * 
     * @param {*} data
     * @param {*} abortController 
     * @returns 
     */

    saveStepStatus(data, abortController) {
        const config = {
            url: '/save/testsuites/steps/status',
            method: 'post',
            data,
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }
    /**
        * 
        * @param {*} data
        * @param {*} abortController 
        * @returns 
        */

    saveStepVideo(data, abortController) {
        const config = {
            url: '/upload/testsuites/video',
            method: 'post',
            data,
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }

    /**
     * 
     * @param {*} data 
     * @param {*} abortController 
     * @returns 
     */
    saveAndClose(data, abortController) {
        const config = {
            url: '/save/close/testsuites',
            method: 'post',
            data,
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }



    /**
    *
    * @param {AbortController} abortController to abort request on unmount
    * @param {Object} Object of query params
    * @returns
    */
    getTestRunnerLogs(abortController, params = null) {
        const config = {
            url: '/testrunner/logs',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }


    /**
    *
    * @param {AbortController} abortController to abort request on unmount
    * @param {Object} Object of query params
    * @returns
    */
    getTestRunnerLogsItems(abortController, params = null) {
        const config = {
            url: '/testrunner/logs/items',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }


    /**
    *
    * @param {AbortController} abortController to abort request on unmount
    * @param {Object} Object of query params
    * @returns
    */
    getTestPlansItems(abortController, params = null) {
        const config = {
            url: '/test-plans-items',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }

    /**
    *
    * @param {AbortController} abortController to abort request on unmount
    * @param {Object} Object of query params
    * @returns
    */
    getTestRunnerStatuses(abortController, params = null) {
        const config = {
            url: '/testrunner/statuses',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }


    /**
     * 
     * @param {AbortController} abortController 
     * @param {String} test_plan_item_suite_id 
     * @param {Array} users 
     * @returns 
     */

    updateUserAgainstTestplanitemsSuite(abortController, test_plan_item_suite_id, users) {
        const config = {
            url: '/update/users/against/testplanitems/suite',
            method: 'put',
            ...(abortController && { signal: abortController.signal }),
            data: { test_plan_item_suite_id, users }
        }
        return api.request(config)
    }

    /**
           * 
           * @param {string} id of testplan 
           * @param {*} status of testplan to update 
           * @returns 
           */
    updateStatus(id, status) {
        const config = { url: `/test-plans/status`, method: 'post', data: { id, status } }
        return api.request(config)
    }

    /**
     * 
     * @param {AbortController} abortController 
     * @param {Array} ids of testplanItems  
     * @returns 
     */
    sortTestPlanItems(abortController, ids) {
        const config = {
            url: `/sort/testsuites/against/testplanitems`,
            method: 'post',
            ...(abortController && { signal: abortController.signal }),
            data: { ids }
        }
        return api.request(config)
    }

    /**
         * 
         * @param {AbortController} abortController 
         * @param {Array} ids of testplanItems  
         * @returns 
         */
    deletePlanItem(abortController, ids) {
        const config = {
            url: `/remove/testsuites/against/testplanitems`,
            method: 'delete',
            ...(abortController && { signal: abortController.signal }),
            data: { ids }
        }
        return api.request(config)
    }

    runTestOnLocal(data) {
        const config = {
            url: 'http://localhost:3009/run',
            method: 'post',
            data: data
        }
        return api.request(config)
    }

    bulkStepsUpdate(data, abortController) {
        console.log(data, "data check")
        const config = {
            url: `/bulk/testsuites/steps/status`,
            method: 'post',
            ...(abortController && { signal: abortController.signal }),
            data: data
        }
        return api.request(config)
    }


    /**
*
* @param {string} id id of suiteId
* @param {AbortController} abortController to abort request on unmount
*
* @returns
*/
    updatePlanItemName(id, data, abortController) {

        const config = {
            url: `/test-plans-items/${id}`,
            method: 'put',
            ...(abortController && { signal: abortController.signal }),
            data: data
        }
        return api.request(config)
    }

    /**
*
* @param {string} id id of suiteId
* @param {AbortController} abortController to abort request on unmount
*
* @returns
*/
    deletePlanItemName(id, abortController) {

        const config = {
            url: `/test-plans-items/${id}`,
            method: 'delete',
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }

}
const testPlanService = new TestPlanService()
export default testPlanService
