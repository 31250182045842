import React, { useState, useRef, useEffect } from "react";

const ToggleComponent = ({ children, defaultCollapsed = true, header }) => {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
    const [contentHeight, setContentHeight] = useState(null);
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [children]);

    useEffect(() => {
        if (!isCollapsed && contentRef.current) {
            // Expand content by setting content height explicitly
            setContentHeight(contentRef.current.scrollHeight);
        } else {
            // Collapse content with a small delay to allow animation
            const timeoutId = setTimeout(() => {
                setContentHeight(0);
            }, 50); // Adjust this delay as needed for smoother animation

            return () => clearTimeout(timeoutId);
        }
    }, [isCollapsed]);

    const toggleHeight = () => {
        setIsCollapsed(prev => !prev);
    };

    const HeadersComponent = () => {
        return header ? (
            header({
                isCollapsed,
                toggle: toggleHeight,
                className: "p-panel-toggleable p-link",
                toggleIcon: `pi pi-${isCollapsed ? "plus" : "minus"} text-2xl`
            })
        ) : (
            <button className="p-panel-toggleable p-link" onClick={toggleHeight}>
                <i className={`pi pi-${isCollapsed ? "plus" : "minus"} text-2xl`}></i>
            </button>
        );
    };

    return (
        <div className="toggle-container">
            <HeadersComponent />
            <div
                ref={contentRef}
                className={`content ${isCollapsed ? "collapsed" : ""}`}
                style={{
                    maxHeight: isCollapsed ? 0 : contentHeight, // Set maxHeight to 0 when collapsed
                    transition: "max-height 0.3s ease", // Smooth transition animation
                }}
                aria-expanded={!isCollapsed}
                aria-hidden={isCollapsed}
            >
                {children}
            </div>
        </div>
    );
};

export default ToggleComponent;
