import api from "../utils/api";

class TestConfiguration {

    /**
        * 
        * @param {*} data
        * * @param {*} id
        * @param {*} abortController 
        * @returns 
        */

    testConfigurationSave(data) {
        const config = {
            url: '/configurations',
            method: 'post',
            data,
        }
        return api.request(config)
    }
    testConfigurationUpdate(id, data) {
        const config = {
            url: `/configurations/${id}`,
            method: 'put',
            data,
        }
        return api.request(config)
    }
    testConfigurationDelete(id) {
        const config = {
            url: `/configurations/${id}`,
            method: 'delete',
        }
        return api.request(config)
    }

    getTestConfigurations(params = null) {
        const config = {
            url: '/configurations',
            method: 'get',
            ...(params && { params: params })
        }
        return api.request(config)
    }

    assignConfigurationsToTest(data) {
        const config = {
            url: '/configurations/add-to-test-suite',
            method: 'post',
            data,
        }
        return api.request(config)
    }
}

const testConfigurationService = new TestConfiguration()
export default testConfigurationService