import React, { useState, useEffect, useRef, Fragment, useCallback } from 'react'

import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import ProjectAdd from './ProjectAdd';

import { projectService } from '../../services/';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { genericTermService } from '../../services/';
export default function ProjectList() {


    const toast = useRef()
    const [projectDialog, setProjectDialog] = useState(false);
    const [rows, setRows] = useState([])
    const [currentActionData, setCurrentActionData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState(null);
    const [selectAll, setSelectAll] = useState(false);

    const [deleteDialog, setDeleteDialog] = useState(false)
    const [pagination, setPagination] = useState({
        first: 0,
        page: 0,
        limit: 10,
        total: 0
    })
    const [filterObj, setfilterObj] = useState({ status: { value: true } })
    const [projectTypeOptions, setProjectTypeOptions] = useState([])
    useEffect(() => {
        const controller = new AbortController();
        genericTermService.getProjectTypes()
            .then(({ data }) => {
                setProjectTypeOptions(data)
            })
            .catch((err) => {
            })
        return () => { controller.abort() }
    }, [])


    const getProjects = useCallback(() => {
        setLoading(true);
        const controller = new AbortController()
        const { project_name, type_id, status, description } = filterObj
        const params = {
            page: pagination.page + 1,
            limit: pagination.limit,
            ...(project_name && { project_name: project_name?.value }),
            ...(status && { status: status?.value }),
            ...(description && { description: description?.value }),
            ...(type_id && { type_id: type_id?.value?.map((val) => val.id).toString() }),

        }
        projectService.getProjects(controller, params)
            .then(({ data }) => {
                const { total } = data.meta
                setRows(data.data)
                setPagination((pg) => ({ ...pg, total }))
            }).catch((err) => {
                console.log(err)
            }).finally(() => setLoading(false))

        return () => {
            controller.abort()
        }
    }, [pagination.page, pagination.limit, filterObj])

    useEffect(() => getProjects(), [getProjects])


    const typeBody = (row) => {

        if (row.type_id === 1) return 'Web'
        if (row.type_id === 2) return 'Mobile'
        if (row.type_id === 2) return 'API'
    }

    const descriptionBody = (row) => {
        return <span style={{
            textOverflow: ' ellipsis',
            width: ' 150px',
            overflow: 'hidden',
            display: 'inline-block',
            whiteSpace: 'nowrap'
        }}>{row.description}</span>
    }
    const actionBody = (row) => {

        return (
            <span className="p-buttonset flex">
                <Button icon="pi pi-trash" size='small' className="p-button-danger" onClick={() => {
                    setCurrentActionData(row)
                    setDeleteDialog(true)
                }}></Button>
                <Button icon="pi pi-pencil" size='small' className="p-button-info" onClick={() => {
                    setCurrentActionData(row)
                    openNew()
                }}></Button>
            </span>
        )
    }

    const deleteDialogFooter = () =>
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteData} />
        </>

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
        setCurrentActionData(null)
    }


    const errorPrint = (err) => {
        if (err.response.data.status_code === 422) {
            toast.current.show({ severity: 'error', summary: err.response.data.errors.toString(), detail: err.response.data.message });
            return
        }
        toast.current.show({ severity: 'error', summary: 'Error ', detail: 'Something went wrong' });
    }

    const deleteData = () => {
        // if bulk deletion else single delete
        if (selectedRows && selectedRows.length) {
            const ids = selectedRows.map((data) => data.id)
            projectService.deleteProjects(ids)
                .then(({ data }) => {
                    hideDeleteDialog()
                    toast.current.show({ severity: 'success', summary: 'Deleted ', detail: 'Selected projects has been deleted.' });
                    setRows((prev) => prev.filter((d) => !ids.includes(d.id)))

                }).catch((err) => {
                    errorPrint(err)
                })
        } else {
            const { id } = currentActionData
            projectService.deleteProject(id)
                .then(({ data }) => {
                    hideDeleteDialog()
                    toast.current.show({ severity: 'success', summary: 'Deleted ', detail: 'Project has been deleted.' });
                    setRows((prev) => prev.filter((d) => d.id !== id))
                }).catch((err) => {
                    errorPrint(err)
                })
        }
    }




    const onSelectionChange = (event) => {
        if (!Array.from(event.originalEvent.target.classList).includes('p-checkbox-box', 'p-checkbox-icon')) {
            return
        }
        const value = event.value;
        setSelectedRows(value);
        setSelectAll(value.length === rows.length);
    }
    const onSelectAllChange = (event) => {
        const selectAll = event.checked;
        setSelectAll(selectAll);
        setSelectedRows(selectAll ? rows : [])

    }

    console.log(selectedRows)


    const openNew = () => {
        // setProduct(emptyProduct);
        // setSubmitted(false);
        setProjectDialog(true)
    }
    const hideProjectDialog = () => {
        setProjectDialog(false)
        setCurrentActionData(null)

    }

    const leftToolbar = () => {
        return (
            <React.Fragment>
                <div className="">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success p-button-text"
                        onClick={openNew} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text "
                        onClick={() => setDeleteDialog(true)}
                        disabled={!selectedRows || !selectedRows.length}
                    />
                </div>
            </React.Fragment>
        )
    }

    const onProjectSubmitSuccess = () => {
        getProjects()
        setProjectDialog(false)
    }

    const statusTemplate = (row) => {
        return <StatusSwitch value={row.status} id={row.id} />
    }

    const onFilter = (e) => {
        console.log(e.filters)
        setfilterObj(e.filters)
    }

    const typeFilterTemplate = (options) => {

        return <MultiSelect
            value={filterObj?.type_id?.value || []}
            options={projectTypeOptions}
            onChange={(e) => options.filterApplyCallback(e.value)}
            optionLabel="name" placeholder="Any" className="p-column-filter" maxSelectedLabels={1} />;
    }

    const statusFilterTemplate = (options) => {
        return <InputSwitch checked={filterObj?.status?.value} onChange={(e) => options.filterApplyCallback(e.value)} />
    }

    // const renderFilterHeader = () => {
    //     return (
    //         <div className="flex justify-content-between">
    //            {/* / <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} /> */}
    //             <span className="p-input-icon-left">
    //                 <i className="pi pi-search" />
    //                 <InputText value={''} onChange={(e)=>console.log(e)} placeholder="Keyword Search" />
    //             </span>
    //         </div>
    //     )
    // }
    return (
        <div className="grid ">
            <div className="col-12">
                <div className="">
                    <Toolbar className="mb-4 p-0" start={leftToolbar} />
                    <Dialog visible={projectDialog} maximizable style={{ width: '650px' }} header="Project Details" modal className="p-fluid" onHide={hideProjectDialog} >
                        <ProjectAdd id={currentActionData?.id} onProjectSubmitSuccess={onProjectSubmitSuccess} />
                    </Dialog>

                    <Toast ref={toast} />

                    <DataTable
                        value={rows}
                        lazy
                        first={pagination.first}
                        totalRecords={pagination.total}
                        paginator
                        rows={pagination.limit}
                        onPage={(event) => {
                            setPagination((pg) => {
                                return { ...pg, page: event.page, first: event.first }
                            })
                        }}
                        dataKey="id"
                        loading={loading}
                        className="datatable-responsive"
                        selectAll={selectAll}
                        onSelectionChange={onSelectionChange}
                        onSelectAllChange={onSelectAllChange}
                        selection={selectedRows}
                        emptyMessage="No Projects found."

                        onFilter={onFilter}
                        filterDisplay="row"
                        // header={renderFilterHeader}

                        responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                        <Column filter showFilterMenu={false} field="project_name" header="Project Name" style={{ minWidth: '23rem' }}  ></Column>
                        <Column filter showFilterMenu={false} field="description" header="Description" body={descriptionBody}  ></Column>
                        <Column filter showFilterMenu={false} filterElement={typeFilterTemplate} field="type_id" header="Type" body={typeBody} ></Column>
                        <Column filter showFilterMenu={false} filterElement={statusFilterTemplate} field="status" header="Status" body={statusTemplate}  ></Column>
                        <Column body={actionBody} header="Actions"  ></Column>

                    </DataTable>

                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            <span>Are you sure you want to delete the Project?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}


function StatusSwitch({ value, id }) {
    const toast = useRef()
    const [checked, setChecked] = useState(parseInt(value) === 1)
    const [loading, setLoading] = useState(false)
    const onChange = () => {
        if (loading) return
        setLoading(true)
        projectService.updateStatus(id, !checked)
            .then((res) => {
                setChecked(!checked)
                toast.current.show({ severity: 'success', summary: 'Updated ', detail: 'Status has been updated.' });
            }).catch((err) => {
                if (err.response.data.status_code === 422) {
                    toast.current.show({ severity: 'error', summary: err.response.data.errors.toString(), detail: err.response.data.message });
                    return
                }
                toast.current.show({ severity: 'error', summary: 'Error ', detail: 'Something went wrong' });

            }).finally(() => setLoading(false))
    }
    return <>
        <Toast ref={toast} />
        <InputSwitch checked={checked} onChange={onChange} />
        {loading && <span className="loader" style={{ borderTop: '3px solid #9e9e9e', marginBottom: '5px' }} ></span>}

    </>
}