import React, { useState, useEffect,useRef } from 'react'
import { useParams } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Formik, ErrorMessage } from 'formik'
import { Toast } from 'primereact/toast';
import * as Yup from 'yup';
import LoaderButton from '../../../components/common/loaderButton/LoaderButton';

import { ticketSystemService } from '../../../services';
// import { Button } from 'primereact/button';

export default function AddTicket() {
    const params = useParams();
    const toast = useRef()
    const [initalValues, setinitalValues] = useState({
        ticketing_systems_id: '',
        test_design_id: params.id,
        user_story: ''
    })
    const [ticketSystemOptions, setTicketSystemOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [uploadLoading,setUploadLoading]=useState(false)

    useEffect(() => {
        setLoading(true)
        const controller = new AbortController()
        const params = { page: 1, limit: 100000 }
        ticketSystemService.getTicketSystems(controller, params)
            .then(({ data }) => {
                setTicketSystemOptions(data.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => setLoading(false))
        return () => {
            controller.abort()
        }
    }, [])

    useEffect(() => {

        return () => {

        }
    }, [])



    const validationSchema = Yup.object({
        ticketing_systems_id: Yup.string().required('Ticketing System is required'),
        test_design_id: Yup.string().required('No Test Design,please save a test first'),
        user_story: Yup.string().required('User Story is required')
    })
    const Err = ({ name }) => {
        return <div style={{ color: '#ff4032' }}>
            <ErrorMessage name={name} />
        </div>
    }
    const onSubmit = async (values) => {
        setSubmitLoading(true)
        const payload = {
            ticketing_systems_id: values.ticketing_systems_id,
            test_design_id: params.id,
            user_story: values.user_story
        }
        try {
            await ticketSystemService.saveTestInTicketSysten(payload)
        } catch (error) {
            showError(error)
        }
        setSubmitLoading(false)
    }

    const uploadToTicketingSystem=async ()=>{
        try {
            setUploadLoading(true)
            await ticketSystemService.uploadStepInTicketSystem({invoked_via_tests:true,test_suites:[params.id]})
            
        } catch (error) {
            showError(error)
        }
        setUploadLoading(false)
    }

    const showError=(error)=>{
        const {response}=error
        if(response.status===422){
            const errMsg = Object.entries(response.data.errors).map((err) => <div>{err[1]}</div>)
            toast.current.show({ severity: 'error', summary: 'Error', detail: errMsg });
        }else{
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
        }
    }
    return (
        <div>
              <Toast ref={toast} />
            <Formik initialValues={initalValues} onSubmit={onSubmit} enableReinitialize validationSchema={validationSchema} >
                {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                    <div>
                        {/* <h5>Advanced</h5> */}
                        <form onSubmit={handleSubmit}>
                            <div className="p-fluid formgrid grid mt-2">
                                <div className="field col-12 md:col-3">
                                    <label htmlFor="ticketing_systems_id">Ticketing System</label>
                                    <select className='p-inputtext p-component' name='ticketing_systems_id' id='ticketing_systems_id' onChange={handleChange} >
                                        <option value='' selected={'' === values.ticketing_systems_id}>Select Ticketing System</option>
                                        {ticketSystemOptions.map(({ name, id }) => <option selected={id === values.id} key={name} value={id}>{name}</option>)}
                                    </select>
                                    <Err name='ticketing_systems_id' />
                                </div>
                                <div className="field col-12 md:col-3">
                                    <label htmlFor="user_story">User Story ID</label>
                                    <InputText name='user_story' type="text" onChange={handleChange} value={values.user_story} />
                                    <Err name='user_story' />
                                </div>

                                <div className='field col-12 md:col-6' style={{ textAlign: 'right',marginTop:'25px' }}>
                                    {/*<Button label="Cancel" type='button' icon="pi pi-times" className="p-button-text w-auto" />*/}
                                    <LoaderButton
                                       
                                        keepText
                                        type='submit'
                                        icon={!submitLoading && "pi pi-check"}
                                        className="p-button-text w-auto"
                                        loading={submitLoading}
                                        spinnerStyle={{ borderTop: '3px solid #6366f1' }}>
                                        Save
                                    </LoaderButton>

                                </div>
                                <div className="field col-12 md:col-12">
                                    <Err name='test_design_id' />
                                </div>
                            </div>
                            {false &&
                                <>
                                    <pre>values:{JSON.stringify(values, false, 2)}</pre>
                                    <pre>errors:{JSON.stringify(errors, false, 2)}</pre></>
                            }



                        </form>
                    </div>
                )}
            </Formik>
            <hr></hr>
            <LoaderButton
                onClick={uploadToTicketingSystem}
                keepText
                type='button'
                icon={!uploadLoading && "pi pi-check"}
                className="p-button-text w-auto"
                loading={uploadLoading}
                spinnerStyle={{ borderTop: '3px solid #6366f1' }}>
                Upload to ticketing system
            </LoaderButton>
            
        </div>
    )
}
