import React, { useRef } from 'react'
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
const VariablesValuesList = ({ values, optionLabel, style, text, btnStyle }) => {
    const op = useRef(null);
    return (
        <span className='flex align-items-center' style={style ? style : {}} >

            <Button text size='small' style={btnStyle ? btnStyle : {}} tooltip={text ?? "View all variables"} tooltipOptions={{ position: 'bottom' }}

                type="button" icon="pi pi-eye" severity='secondary' className='p-1' onClick={(e) => op.current.toggle(e)} >
                <span className="white-space-nowrap ml-1 overflow-hidden text-overflow-ellipsis" style={{ maxWidth: "100px" }}>
                    {text ?? "List"}
                </span>
            </Button>
            <OverlayPanel ref={op} className='p-0' pt={{
                content: { className: 'p-0 w-16' },
                root: { style: { maxWidth: "14rem" } }
            }}>
                <ListBox options={values ?? []} filter listStyle={{ maxHeight: '150px' }}
                    pt={{
                        header: { className: "p-2" },
                        filterInput: { className: "h-1.5rem", style: { maxHeight: "25px" } }
                    }}
                    optionLabel={optionLabel ?? "name"} className="w-full" />
            </OverlayPanel>

        </span>
    )
}

export default VariablesValuesList