import React, { useEffect, useState } from 'react';
import QAModal from '../../components/common/Modal/indext';
import Spinner from '../../components/common/Spinner/Spinner';
import { testPlanService } from '../../services';
import Toggleable from '../TestPlan/Logs/Fieldset';
import AlertMsg from '../../components/common/AlertMsg/AlertMsg';

const SuitsSteps = ({ isOpen, closeModal, id }) => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      setLoading(true); // Set loading to true initially
      const data = {
        test_suites: [id],
        invoked_via_tests: true,
      };
      testPlanService
        .getTestSuitsSteps(data)
        .then(res => {
          console.log(res , "TestSuitsSteps");
          setTests(res.data.test_runner_steps);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => setLoading(false)); // Set loading to false when data is fetched
    } else {
      setTests([]);
    }
  }, [isOpen, id]);

  const modalHeader =  <div className='flex' ><div style={{width : "calc(90vmin - 50vmax)"}} className=' white-space-nowrap overflow-hidden text-overflow-ellipsis flex-1'>{tests[0]?.test_suite?.name}</div></div>;
  
  return (
    <QAModal header={modalHeader} isOpen={isOpen} closeModal={closeModal}>
      {loading ? ( // Show Spinner if loading is true
        <Spinner />
      ) : (
        tests[0]?.steps.length > 0 ? ( // Show steps if there are steps available
          <div className='p-dialog-content p-0'>
            {tests[0]?.steps.map((step, i) => {
              return <Toggleable data={step} index={i} key={i.toString()} noActionBtn={true} />;
            })}
          </div>
        ) : (
          <AlertMsg>
            <div className='ml-2'>No Steps.</div>
          </AlertMsg>
        )
      )}
    </QAModal>
  );
};

export default SuitsSteps;
