import api from "../utils/api";

class ConfigVariables {

    /**
        * 
        * @param {*} data
        * * @param {*} id
        * @param {*} abortController 
        * @returns 
        */

    configVariablesSave(data) {
        const config = {
            url: '/configuration-variables',
            method: 'post',
            data,
        }
        return api.request(config)
    }
    configVariablesUpdate(id, data) {
        const config = {
            url: `/configuration-variables/${id}`,
            method: 'put',
            data,
        }
        return api.request(config)
    }
    configVariablesDelete(id) {
        const config = {
            url: `/configuration-variables/${id}`,
            method: 'delete',
        }
        return api.request(config)
    }

    getConfigVariables(params = null) {
        const config = {
            url: '/configuration-variables',
            method: 'get',
            ...(params && { params: params })
        }
        return api.request(config)
    }
}

const configVariablesService = new ConfigVariables()
export default configVariablesService