import React from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

function AlertDialog({onYes,onNo,message,show,onHide}) {

    const dialogFooter = () =>
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={onNo} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={onYes} />
        </>
    return (

        <Dialog visible={show} style={{ width: '450px' }} header="Confirm" modal footer={dialogFooter} onHide={onHide}>
            <div className="flex align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <span>{
                    message?message:'Are you sure, you want to do this action?'
                }</span>
            </div>
        </Dialog>
    );
}

export default AlertDialog;
