import React, { useState, useEffect, forwardRef } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { useMutation, useQuery } from 'react-query';
import configVariablesService from '../../services/configVariables';
import testConfigurationService from '../../services/testConfiguration';
import { Message } from 'primereact/message';
import { Link } from 'react-router-dom';
const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required'),
    status: yup.boolean().required('status is required'),
    configuration_variables: yup.array().of(
        yup.object().shape({
            variable_id: yup.string().required('Variable is required'),
            variable_value_id: yup.string().required('variable valueis required'),
        })
    ),
});

const FormModal = forwardRef(({ visible, setVisible, refreshTable, valuesForUpdate, resetState }, ref) => {
    const [availableValues, setAvailableValues] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const defaultValues = {
        name: '',
        description: '',
        status: true,
        configuration_variables: [{ variable_id: '', variable_value_id: '' }],
    }
    const { control, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'configuration_variables',
    });

    useEffect(() => {
        valuesForUpdate && Object.keys(defaultValues).forEach(key => setValue(key, key === "status" ? valuesForUpdate[key] === 1 : valuesForUpdate[key]))

    }, [valuesForUpdate])




    const { isLoading } = useQuery({
        queryKey: ["configuration_variables"],
        queryFn: async () => {
            const res = await configVariablesService.getConfigVariables();
            return res?.data ?? []
        },
        onSuccess: (data) => {
            setAvailableValues(data)
        }
    })
    const check = () => {
        return availableValues?.length === watch("configuration_variables").length ? true : false
    }

    const extractVariablesValues = (id) => {
        if (id) {

            const _value = availableValues.find(value => value.id === id);

            return _value ? _value?.variable_values : []
        }

        return []

    }

    const saveConfig = async (values) => {
        return await testConfigurationService.testConfigurationSave({ ...values })
    }
    const updateConfig = async (values) => {
        return await testConfigurationService.testConfigurationUpdate(valuesForUpdate?.id, { ...values, values: values?.variable_values, })
    }
    const { mutate: onSubmit,  } = useMutation(async (values) => {
   
        const response = valuesForUpdate ? updateConfig(values) : saveConfig(values)
        return response;
    }, {
        onError: (error) => {
            if (error.response.status === 500) {
                ref.current.show({ severity: 'error', summary: 'Error', detail: 'Internal Server Error!' });
                return
            }
            ref.current.show({ severity: 'error', summary: 'Error', detail: error?.response?.data?.message ?? 'Opps! Something went wrong' });


        },
        onSuccess: async (res) => {
            refreshTable();
            resetAll()
            ref.current.show({ severity: 'success', summary: 'Alert!', detail: res?.message });
        },
    });

    const resetAll = () => {
        if (valuesForUpdate) {
            resetState(null)
        }
        reset()
        setVisible(false);
    }
    useEffect(() => {
        // Update selected options when the form data changes
        const selectedValues = watch("configuration_variables").map((item) => item.variable_id);
        setSelectedOptions(selectedValues);
    }, [watch("configuration_variables")]);
    return (
        <Dialog contentStyle={{ width: "80vw" }} visible={visible} onHide={() => resetAll()}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className='formgrid grid'>

                    <div className=" col-6">
                        <div className='field'>
                            <label htmlFor="name"><strong>Name</strong></label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <InputText
                                        className='w-full'
                                        id="name"
                                        {...field}
                                        placeholder="Select a name"
                                    />
                                )}
                            />
                            <small className="p-error">{errors.name?.message}</small>
                        </div>
                        <div className="field">
                            <label htmlFor="status"><strong>Status</strong></label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <Dropdown
                                        className='w-full'
                                        id="status"
                                        {...field}
                                        options={[{ label: "Active", value: true }, { label: "Inactive", value: false }]}
                                        placeholder="Select a status"
                                    />
                                )}
                            />
                            <small className="p-error">{errors.status?.message}</small>
                        </div>
                    </div>

                    <div className="field col-6 mb-0">
                        <label htmlFor="description"><strong>Description</strong></label>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => <InputTextarea className='w-full h-full' id="description" {...field} />}
                        />
                        <small className="p-error">{errors.description?.message}</small>
                    </div>



                    {availableValues.length ? <div className="field col-12 mt-5">
                        <label>Configuration Values</label>

                        <div>
                            {fields.map((item, index) => (
                                <div key={item.id}>
                                    <div className="flex field gap-3">
                                        <Controller
                                            name={`configuration_variables[${index}].variable_id`}
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                const filteredAvailableValues = availableValues.map(option => {
                                                    return {
                                                        ...option,
                                                        disabled: selectedOptions.includes(option.id),
                                                    };
                                                });
                                                return <div className='w-full'>
                                                    <Dropdown
                                                        checked={true}
                                                        className='w-full'
                                                        disabled={isLoading}
                                                        value={value}
                                                        options={filteredAvailableValues}
                                                        onChange={(el) => {
                                                            // Enable the previously selected value in the same dropdown
                                                            if (selectedOptions[index] !== el) {
                                                                setSelectedOptions((prev) => [...prev.filter(option => option !== selectedOptions[index]), el.value]);
                                                            }
                                                            onChange(el);
                                                        }}
                                                        optionLabel='name'
                                                        optionValue='id'
                                                        placeholder="Variable Name"
                                                    />
                                                    <p className="p-error">
                                                        {errors.configuration_variables && errors.configuration_variables[index]?.variable_id?.message}
                                                    </p>
                                                </div>
                                            }}
                                        />
                                        <Controller
                                            name={`configuration_variables[${index}].variable_value_id`}
                                            control={control}
                                            render={({ field }) => <div className='w-full'>
                                                <Dropdown
                                                    disabled={true}
                                                    className='w-full'
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    options={extractVariablesValues(watch(`configuration_variables[${index}].variable_id`))}
                                                    {...field}
                                                    placeholder="Value" />

                                                <p className="p-error">
                                                    {errors.configuration_variables && errors.configuration_variables[index]?.variable_value_id?.message}
                                                </p>
                                            </div>}
                                        />
                                        {watch("configuration_variables").length > 1 && <Button text severity='danger' type="button"
                                            onClick={() => {
                                                delete selectedOptions[index];
                                                remove(index);
                                            }
                                            } icon="pi pi-times" />}

                                    </div>
                                </div>
                            ))}
                            <Button
                                type="button"
                                disabled={check()}
                                icon="pi pi-plus"
                                onClick={() => !check() ? append({ variable_id: '', variable_value_id: '' }) : ""}
                                text
                                // tooltipOptions={{ showOnDisabled: true }}
                                // tooltip={`Only ${watch("configuration_variables").length} Configuration values are available`}
                                size='small'
                                label="Add Variable"
                            />
                        </div>

                    </div> :
                        <Message className='my-5' severity="error" text={
                            <>
                                No Configuration Variables values are available. Please add at least one configuration value {<Link to="/config-variables">Click Here </Link>}
                            </>
                        } />

                    }

                    <div className="field col-12 text-right">
                        {availableValues.length ? <Button size='small' type="submit" label="Submit" loading={isLoading} disabled={isLoading} /> : null}
                    </div>
                </div>
                <div>
                    {false && <>
                        <code>
                            <pre>Values: {JSON.stringify(watch(), null, 2)}</pre>
                        </code>
                        <code>
                            <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                        </code>
                    </>
                    }
                </div>
            </form>
        </Dialog>
    );
}
)

export default FormModal;
