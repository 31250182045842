import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';

export default function ActionBuilder() {
  const [droppedActions, setDroppedActions] = useState([]);
  const [draggedActionIndex, setDraggedActionIndex] = useState(null);
  const [finalString, setFinalString] = useState('');
  const actionOptions = [
    { label: 'innerText', value: 'innerText' },
    { label: 'outerText', value: 'outerText' },
    { label: 'value', value: 'value' },
    { label: 'isSelected', value: 'isSelected' },
    { label: 'isEnabled', value: 'isEnabled' },
    { label: 'isDisplayed', value: 'isDisplayed' },
    { label: 'getText', value: 'getText' },
    { label: 'selection', value: 'selection' },
  ];
  const [fields, setFields] = useState([
    { id: 'wait', label: 'Wait (ms)', component: 'number', value: null, placeholder: 'Enter wait time in ms' },
    { id: 'getElementValue', label: 'Get Element Value', component: 'dropdown', value: '', options: actionOptions, placeholder: 'Select' },
    { id: 'focusOut', label: 'Focus Out', component: 'text', value: '', placeholder: 'Enter focus text' },
    { id: 'scrollToText', label: 'Scroll to Text', component: 'text', value: '', placeholder: 'Enter text to scroll to' },
    { id: 'switchToIframe', label: 'Switch to Iframe XPath', component: 'text', value: '', placeholder: 'Enter iframe XPath' },
    { id: 'switchBrowser', label: 'Switch Browser Tab Index', component: 'number', value: null, placeholder: 'Enter tab index' },
  ]);


  const keyboardActions = ['backspace', 'clear', 'click', 'copy', 'cut', 'delete', 'enter', 'esc', 'escape', 'hoverElement', 'keydown', 'keyup', 'maxBrowser', 'minBrowser', 'paste', 'selectall', 'tab'];

  const addDroppedAction = action => {
    setDroppedActions([...droppedActions, action]);
  };

  const removeDroppedAction = index => {
    setDroppedActions(droppedActions.filter((_, i) => i !== index));
  };

  const handleDragStart = index => {
    setDraggedActionIndex(index);
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = index => {
    if (draggedActionIndex !== null) {
      const updatedActions = [...droppedActions];
      const [movedAction] = updatedActions.splice(draggedActionIndex, 1);
      updatedActions.splice(index, 0, movedAction);

      setDroppedActions(updatedActions);
      setDraggedActionIndex(null);
    
    }
  };

  const copyToClipboard = () => navigator.clipboard.writeText(finalString);
  const buildString = () => {
    const parts = [];

   fields
    .filter(field => field.value !== '' && field.value !== null) // Only include fields with values
    .forEach(field => parts.push(`${field.id}=${field.value}`))

    if (droppedActions.length) parts.push(`actionChain=${droppedActions.join('>>')}`);

    setFinalString(parts.join('||'));
  };

  const clearInputFields = () => {
    setFields(fields.map(field => ({ ...field, value: field.component === 'number' ? null : '' })));
    setDroppedActions([]);
    setFinalString('');
  };


  const [draggedFieldIndex, setDraggedFieldIndex] = useState(null);

  const handleFieldDragStart = (index) => {
    setDraggedFieldIndex(index);
  };

  const handleFieldDragOver = (e) => {
    e.preventDefault();
  };

  const handleFieldDrop = (index) => {
    if (draggedFieldIndex !== null) {
      const updatedFields = [...fields];
      const [movedField] = updatedFields.splice(draggedFieldIndex, 1); // Remove the dragged field
      updatedFields.splice(index, 0, movedField); // Insert it at the drop index

      setFields(updatedFields); // Update the state
      setDraggedFieldIndex(null); // Reset dragged field index
    }
  
  };

  const handleFieldChange = (id, newValue) => {
    setFields(fields.map(field => field.id === id ? { ...field, value: newValue } : field));
  
  };

  // Handle string reordering of fields if string is built already
  return (
    <div className='p-m-4'>
      <h1>Build Dynamic Action String</h1>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '10px' }}>
        {fields.map((field, index) => (
          <div
          role="button"
            key={field.id}
            draggable
            onDragStart={() => handleFieldDragStart(index)}
            onDragOver={handleFieldDragOver}
            onDrop={() => handleFieldDrop(index)}
            className="flex flex-column py-2 px-3 gap-2 bg-black-alpha-10 cursor-move border-round-xl border-1 border-black-alpha-10"
          >
            <label htmlFor={field.id} className='cursor-move font-medium'>{field.label}</label>
            {field.component === 'number' && (
              <InputNumber
                value={field.value}
                onChange={(e) => handleFieldChange(field.id, e.value)}
                placeholder={field.placeholder}
                style={{ width: '100%' }}
                useGrouping={false}
                id={field.id}
              />
            )}
            {field.component === 'text' && (
              <InputText
                value={field.value}
                onChange={(e) => handleFieldChange(field.id, e.target.value)}
                placeholder={field.placeholder}
                style={{ width: '100%' }}
              />
            )}
            {field.component === 'dropdown' && (
              <Dropdown
                value={field.value}
                options={field.options}
                onChange={(e) => handleFieldChange(field.id, e.value)}
                placeholder={field.placeholder}
                style={{ width: '100%' }}
              />
            )}
          </div>
        ))}
      </div>

      <Divider />

      <h3>Click to Select Keyboard Actions:</h3>
      <div className='flex flex-wrap gap-2'>
        {keyboardActions.map(action => (
          <Button key={action} label={action} badge={droppedActions.filter(a => a === action).length} className='p-button-info p-button-sm' onClick={() => addDroppedAction(action)} />
        ))}
      </div>

      <h3>Selected Actions:</h3>
      <div className='flex flex-wrap mt-2 p-3 gap-2' style={{ border: '2px dashed #ccc', minHeight: '50px' }}>
        {droppedActions.map((action, index) => (
          <span role='row' key={action + '_' + (index + 1) + '_btn'} className='p-buttonset' draggable onDragStart={() => handleDragStart(index)} onDragOver={handleDragOver} onDrop={() => handleDrop(index)}>
            <Button label={action} text raised className=' p-button-sm' />
            <Button icon='pi pi-times' raised className='p-button-danger p-button-sm' onClick={() => removeDroppedAction(index)} />
          </span>
        ))}
      </div>
      <div className='my-4'>
        <h3>Generated Action String:</h3>
        <div className='p-inputgroup'>
          <div className='p-inputtext p-component bg-black-alpha-10  text-lg ' style={{ width: '100%', fontFamily: 'monospace', resize: 'none', borderRadius: '10px 0px 0px 10px' , lineBreak: 'anywhere' }}>
            {finalString}
          </div>
          <Button icon='pi pi-copy' className='p-button-secondary' onClick={copyToClipboard} tooltip='Copy to clipboard' tooltipOptions={{ position: 'bottom' }} />
        </div>
      </div>
      <div className='col-12 mt-2 p-buttonset'>
      <Button label='Build String' className='p-button-success p-mt-3' onClick={buildString} />
      <Button label='Reset Fields' className='p-button-warning' onClick={clearInputFields} />
      </div>
    </div>
    
  );
}
