import {folderActions} from "../actions";

const initialState = {
    folders: [],
    suites: [],
    parent: null,
    currentEntity: 'folder',
    crumbs: []
};

const FolderReducer = (state = initialState, action) => {
    // console.log({action,state});
    const {data} = action

    switch (action.type) {
        case folderActions.SET_FOLDERS:
            return {...state, folders: data}

        case folderActions.SET_PARENT:
            return {...state, parent: data}

        case folderActions.SET_CURRENT_ENTITY:
            return {...state, currentEntity: data}

        case folderActions.SET_CRUMBS:
            return {...state, crumbs: data}

        case folderActions.SET_SUITES:
            return {...state,suites:data}

        case folderActions.RESET:
            return {
                folders: [],
                suites: [],
                parent: null,
                currentEntity: 'folder',
                crumbs: []
            };

        default:
            // console.log({initialState},'defualt')
            return state

    }
}

export default FolderReducer;
