import React, { useEffect, useState } from 'react'
import { Dialog } from "primereact/dialog"
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useMutation } from 'react-query';
import configVariablesService from '../../services/configVariables';
import { forwardRef } from 'react';
const FormModal = forwardRef(({ visible, setVisible, refreshTable, valuesForUpdate, resetState }, ref) => {
    const [idsForDelete, setIds] = useState([])
    const schema = yup.object().shape({
        name: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
        variable_values: yup.array().of(yup.object().shape({
            name: yup.string().required('Variable name is required')
        }))
    });

    const defaultValues = {
        name: '',
        description: '',
        variable_values: [{ name: '' }]
    }
    const { control, handleSubmit, watch, setValue, getValues, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });
    useEffect(() => {
        valuesForUpdate && Object.keys(defaultValues).forEach(key => setValue(key, valuesForUpdate[key]))

    }, [valuesForUpdate])
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'variable_values',
    });

    const resetAll = () => {
        if (valuesForUpdate) {
            resetState(null)
        }
        
        reset();
        setVisible(false)

    }

    const saveConfig = async (values) => {
        return await configVariablesService.configVariablesSave({ ...values, values: values?.variable_values })
    }
    const updateConfig = async (values) => {
        return await configVariablesService.configVariablesUpdate(valuesForUpdate?.id, { ...values, values: values?.variable_values, deleted_values: idsForDelete })
    }
    const { mutate: onSubmit, isLoading } = useMutation(async (values) => {
        const response = valuesForUpdate ? updateConfig(values) : saveConfig(values)
        return response;
    }, {
        onError: (error) => {
            if (error.response.status === 500) {
                ref.current.show({ severity: 'error', summary: 'Error', detail: 'Internal Server Error!' });
                return
            }
            ref.current.show({ severity: 'error', summary: 'Error', detail: error?.response?.data?.message ?? 'Opps! Something went wrong' });


        },
        onSuccess: async (res) => {
            refreshTable();
            resetAll()
            ref.current.show({ severity: 'success', summary: 'Alert!', detail: res?.message });
        },
    });

    const removeField = (index) => {
        if (valuesForUpdate) {
            const value = getValues(`variable_values`)?.at(index);
            if (value?.id) {
                setIds(prev => [value?.id, ...prev]);
            }

        }
        remove(index)
    }



    function getOrdinal(index) {
        const suffixes = ["th", "st", "nd", "rd"];
        const lastTwoDigits = index % 100;
        const lastDigit = lastTwoDigits % 10;
        const suffix = suffixes[lastDigit] || "th";

        // Special case for 11, 12, and 13
        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return index + "th";
        }

        return index + suffix;
    }

    return (
        <Dialog contentClassName='pb-0' header="Add Configuration Variables " className='w-full' visible={visible} onHide={() =>resetAll()} style={{ maxWidth: "700px" }}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='formgrid grid'>
                    <div className="field col-12">
                        <label htmlFor="name"><strong>Name</strong></label>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => <InputText placeholder='Enter Configuration Name' autoComplete='off' id="name" {...field} className='w-full' />}
                        />
                        <small className="p-error">{errors.name?.message}</small>
                    </div>

                    <div className="field col-12">
                        <label htmlFor="description"><strong>Description</strong></label>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => <InputTextarea placeholder='Some Description .....' className='w-full' id="description" {...field} rows={3} autoResize={false} />}
                        />
                        <small className="p-error">{errors.description?.message}</small>
                    </div>

                    <div className='field col-12'>
                        <label className='my-2 block'><strong>Variable Values</strong></label>
                        {fields.map((inputs, index) => (
                            <div key={inputs.id}>
                                <Controller
                                    name={`variable_values.${index}.name`}
                                    control={control}
                                    render={({ field }) => <>
                                        <div className='flex mb-2'>
                                            <InputText placeholder={`${getOrdinal(index + 1)} value`} autoComplete='off' id="name" {...field} className='w-full' />
                                            {watch("variable_values").length > 1 && <Button text severity='danger' type="button" onClick={() => removeField(index)} icon="pi pi-times" />}
                                        </div>
                                        {errors.variable_values && <small className="p-error">{errors.variable_values?.at(index)?.name?.message}</small>}
                                    </>
                                    }
                                />


                            </div>
                        ))}
                        <Button className='mt-2' type="button" text icon="pi pi-plus" onClick={() => append({ name: '' })} label='Add Variable' />

                    </div>

                    <div className="field col-12 text-right p-4 sticky bg-white bottom-0 ">
                        <Button type="submit" label="Submit" loading={isLoading} />
                    </div>
                </div>
                {false && <>
                    <code>
                        <pre>Values: {JSON.stringify(watch(), null, 2)}</pre>
                    </code>
                    <code>
                        <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                    </code>
                </>
                }
            </form>
        </Dialog>

    )
})

export default FormModal