import api from "../utils/api";
class ProjectService{
    /**
     * 
     * @param {integer} page page number 
     * @param {AbortController} abortController to abort request on unmount
     * @param {Object} Object of query params 
     * @returns 
     */
    getProjects(abortController,params=null){
        const config={
            url: '/projects',
            method: 'get',
            ...(abortController&&{signal: abortController.signal}),
            ...(params && {params:params})
        }
        return api.request(config)
    }

    /**
     * 
     * @param {string} id id of project
     * @param {AbortController} abortController to abort request on unmount
     * @returns 
     */
    getProject(id,abortController){
    
        const config={ url: `/projects/${id}`, method: 'get',...(abortController&&{signal: abortController.signal})}
        return api.request(config)
    }

    /**
     * 
     * @param {object} data Object of project data 
     * @returns 
     */
    createProject(data){
        const config={url:'/projects',method:'post',data}
        return api.request(config)
    }

    /**
     * 
     * @param {string} id id of project to update
     * @param {Object} data Object of project data 
     * @returns 
     */
    updateProject(id,data){
        const config={url:`/projects/${id}`,method:'patch',data}
        return api.request(config)
    }

    /**
     * 
     * @param {string} id id of project
     * @returns 
     */
    deleteProject(id){
        const config={ url: `/projects/${id}`, method: 'delete' }
        return api.request(config)
    }

    /**
     * 
     * @param {array<string>} ids ids of projects
     * @returns 
     */
    deleteProjects(ids){
        const config={ url: `/projects/bulk-delete`, method: 'post', data: { project_ids: ids } }
        return api.request(config)
    }

    /**
     * 
     * @param {string} id of project 
     * @param {*} status of project to update 
     * @returns 
     */
    updateStatus(id,status){
        const config={ url: `/projects/status`, method: 'post', data: { id,status } }
        return api.request(config)
    }
}
const projectService = new ProjectService()
export default projectService