import { configureStore,combineReducers  } from '@reduxjs/toolkit'
import AuthReducer from './reducers/auth.reducer';
import FolderReducer from './reducers/folder.reducer';
import SuiteReducer from "./reducers/suite.reducer";
import TestPlanReducer from './reducers/testPlan.reducer';
const rootReducer = combineReducers({
    auth: AuthReducer,
    folders:FolderReducer,
    suite:SuiteReducer,
    testPlan:TestPlanReducer
  });

export default configureStore({
    reducer: rootReducer
})
