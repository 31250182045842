import React, { useState } from 'react'
import { InputTextarea } from 'primereact/inputtextarea';
import style from "./onfaild.module.scss"
import { Button } from 'primereact/button';
import { Image } from "primereact/image"
const CommentAndImage = ({ index, callback }) => {
    const [images, setImages] = useState([])
    const [comment, setComment] = useState("")
    const onUpload = (e) => {
        if (e.target.files.length > 0) {
            const getFiles = e.target.files
            console.log(getFiles)
            e.target.files && setImages([...getFiles])
        } else {
            setImages(prev => prev = [])
        }
    }
    const deleteImg = (index) => {
        const newImages = images.filter((img, i) => i !== index)
        setImages(prev => prev = newImages)
    }
    const updateStatus = () => {
        console.log(images)
        if (comment.trim() === "" && images.length === 0) {
            alert("Please provide a reason in the comment box or attach some screenshots.");
        } else {
            callback(index, false, () => { }, comment, images);
        }
    };
    console.log(callback, "callbackcallback")

    return (
        <div>
            <div>Comment:</div>
            <div className={style.main_div}>
                <InputTextarea cols={45} className={style.commentBox} onBlur={(e) => setComment(e.target.value)} />
                <div className={style.fileupload + " "}>
                    <label htmlFor='uploadIMG' className='p-button-label p-c p-button p-component  w-auto  p-button-raised p-button-sm'><i className=' pi pi-plus '></i> Select</label>
                    <input id='uploadIMG' type='file' onChange={onUpload} multiple accept="image/*" />
                </div>

                <Button tooltip='Click to update the status of the failed operation.' tooltipOptions={{ position: "bottom" }}
                    size="small" label='Update' raised icon="pi pi-arrow-up-right"
                    className=' w-auto ' onClick={() => updateStatus()} iconPos='right'
                />
            </div>
            {images.length > 0 ? <div className={style.gallery}>

                {images.map((img, i) =>

                    <div className={style.imagePeview} key={i.toString()} >
                        <i className='pi pi-times' onClick={() => deleteImg(i)}></i>
                        <Image src={URL.createObjectURL(img)} imageClassName={style.preview} preview />
                    </div>
                )
                }
            </div> : ""}
        </div>
    )
}

export default CommentAndImage