import api from "../utils/api";

class ComponentService {

    /**
   *
   * @param {AbortController} abortController to abort request on unmount
   * @returns
   */
    getKeywords(abortController,params=null) {

        const config = { 
            url: `/keywords`, 
            method: 'get',
             ...(abortController && { signal: abortController.signal }),
             ...(params && {params:params})
             }
        return api.request(config)
    }

    /**
     *
     * @param {string} ids ids of keywords
     * @returns
     */
    deleteKeywords(ids){
        const config={ url: `/keywords`, method: 'delete',data:{keywords_ids :ids} }
        return api.request(config)
    }

    createGroupKeyword(data){
        const config={url:'/keywords',method:'post',data}
        return api.request(config)
    }

    /**
       *
       * @param {AbortController} abortController to abort request on unmount
       * @returns
       */
    getBeforeAfterSteps(abortController) {

        const config = { url: `/before-after-steps`, method: 'get', ...(abortController && { signal: abortController.signal }) }
        return api.request(config)
    }

    /**
     *
     * @param {object} data Object of project data
     * @returns
     */
     createComponent(data){
        const config={url:'/components',method:'post',data}
        return api.request(config)
    }

     /**
     *
     * @param {AbortController} abortController to abort request on unmount
     * @param {Object} Object of query params
     * @returns
     */
      getComponents(abortController,params=null) {
        const config = {
            url: '/components',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && {params:params})
        }
        return api.request(config)
    }


    /**
   *
   * @param {string} id id of component
   * @param {AbortController} abortController to abort request on unmount
   *
   * @returns
   */
     getComponent(id, abortController) {

        const config = {
            url: `/components/${id}`,
            method: 'get', ...(abortController && { signal: abortController.signal }),

        }
        return api.request(config)
    }

    /**
   *
   * @param {string} id id of component
   * @param {object} data data of component
   * @param {AbortController} abortController to abort request on unmount
   * @returns
   */
     updateComponent(id, data, abortController=null) {

        const config = { url: `/components/${id}`, method: 'patch', data:data,...(abortController && { signal: abortController.signal }) }
        return api.request(config)
    }
    /**
     *
     * @param {string} id id of component
     * @returns
     */
     deleteComponent(id){
        const config={ url: `/components/${id}`, method: 'delete' }
        return api.request(config)
    }

    /**
     *
     * @param {array<string>} ids ids of projects
     * @returns
     */
     deleteComponents(ids){
        const config={ url: `/components/bulk-delete`, method: 'post', data: { component_ids: ids } }
        return api.request(config)
    }
    /**
        * 
        * @param {string} id of component 
        * @param {*} status of component to update 
        * @returns 
        */
    updateStatus(id, status) {
        const config = { url: `/components/status`, method: 'post', data: { id, status } }
        return api.request(config)
    }
    /**
     * 
     * @param {FormData*} formdata object of file
     * @returns 
     */

    importComponents(formdata){
        const config={ url: `/import/components`, method: 'post', data:formdata }
        return api.request(config)
        
    }
}

const componentService = new ComponentService()
export default componentService
