import api from "../utils/api";

class CustomVariablesService {

    /**
     *
     * @param {object} data Object of project data
     * @returns
     */
     createCustomVariable(data){
        const config={url:'/custom/variables',method:'post',data}
        return api.request(config)
    }

     /**
     *
     * @param {AbortController} abortController to abort request on unmount
     * @param {Object} Object of query params
     * @returns
     */
      getVariableTypes(abortController,params=null) {
        const config = {
            url: '/variables/types',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && {params:params})
        }
        return api.request(config)
    }


     /**
     *
     * @param {AbortController} abortController to abort request on unmount
     * @param {Object} Object of query params
     * @returns
     */
      getVariables(abortController,params=null) {
        const config = {
            url: '/custom/variables',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && {params:params})
        }
        return api.request(config)
    }


//     /**
//    *
//    * @param {string} id id of component
//    * @param {AbortController} abortController to abort request on unmount
//    *
//    * @returns
//    */
//      getComponent(id, abortController) {

//         const config = {
//             url: `/components/${id}`,
//             method: 'get', ...(abortController && { signal: abortController.signal }),

//         }
//         return api.request(config)
//     }

    /**
   *
   * @param {string} id id of custom variable
   * @param {object} data data of custom variable
   * @param {AbortController} abortController to abort request on unmount
   * @returns
   */
     updateCustomVariables(id, data, abortController=null) {

        const config = { url: `/custom/variables/${id}`, method: 'patch', data:data,...(abortController && { signal: abortController.signal }) }
        return api.request(config)
    }
    /**
     *
     * @param {string} id id of custom variable
     * @returns
     */
     deleteCustomVariables(id){
        const config={ url: `/custom/variables/${id}`, method: 'delete' }
        return api.request(config)
    }

//     /**
//      *
//      * @param {array<string>} ids ids of projects
//      * @returns
//      */
//      deleteComponents(ids){
//         const config={ url: `/components/bulk-delete`, method: 'post', data: { component_ids: ids } }
//         return api.request(config)
//     }
//     /**
//         * 
//         * @param {string} id of component 
//         * @param {*} status of component to update 
//         * @returns 
//         */
//     updateStatus(id, status) {
//         const config = { url: `/components/status`, method: 'post', data: { id, status } }
//         return api.request(config)
//     }
//     /**
//      * 
//      * @param {FormData*} formdata object of file
//      * @returns 
//      */

//     importComponents(formdata){
//         const config={ url: `/import/components`, method: 'post', data:formdata }
//         return api.request(config)
        
//     }
}

const customVariablesService = new CustomVariablesService()
export default customVariablesService
