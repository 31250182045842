import api from "../utils/api";
import { getUserSettings, setUserSettings } from "../utils/common";

class UserSettings {

    // sample Object of User Settings
    // {
    //     preferences:{ //root
    //         somePage:{ //page
    //             ...some attributes named as updated settings
    //         }
    //     }
    // }

    /**
        * 
        * @param {Object} data Object of user settings 
        * @returns 
        */
    updateSetting(root, page, updatedSetting) {
        let settings = getUserSettings()
        //this 3 if scenario runs when user is setting for this first time 
        if (Array.isArray(settings)) {
            settings = {}
        }

        if (settings[root] === undefined) {
            Object.assign(settings, { [root]: {} })
        }
        if (settings[root] && settings[root][page] === undefined) {
            Object.assign(settings[root], { [page]: {} })
        }

        settings[root][page] = { ...settings[root][page], ...updatedSetting }
        console.log(settings)
        setUserSettings(settings)
        const config = { url: `/users/settings`, method: 'post', data: { settings } }
        return api.request(config)
    }



    /**
        * 
        * @param {*} data
        * @param {*} abortController 
        * @returns 
        */

    userThemeSettingSave(data, abortController) {
        const config = {
            url: '/users/settings',
            method: 'post',
            data,
            ...(abortController && { signal: abortController.signal }),
        }
        return api.request(config)
    }

    getUsersThemeSettings(abortController, params = null) {
        const config = {
            url: '/users/settings',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && { params: params })
        }
        return api.request(config)
    }
}

const userSettingsService = new UserSettings()
export default userSettingsService