import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';

const QAModal = ({ isOpen, closeModal, header = 'Header', children, footer = null, ...rest }) => {
  return (
    <Dialog
      header={header ?? 'Header'}
      visible={isOpen}
      style={{ width: '50vw' }}
      onHide={() => {
       
        closeModal();
      }}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default QAModal;
QAModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  header: PropTypes.element,
  children: PropTypes.node,
  footer: PropTypes.node,
};
