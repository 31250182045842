import React, { useState } from 'react';
import { Button } from "primereact/button"
// Global utility function to truncate a string
function truncateString(text, maxLength) {
    if (text) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';

    }
}

function LongStringWithSeeMore({ content, maxLength, classes, hideSeeMore }) {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = (e) => {
        e.stopPropagation();
        setExpanded(!expanded);
    };

    const truncatedContent = truncateString(content, 150);

    return (
        <span className={classes} style={{maxInlineSize : "300px", display : "block"}}>
            {content && (expanded ? content : truncatedContent)}
            {content && content.length > 150 && !hideSeeMore && (
                <Button className='p-0 m-0 w-auto vertical-align-middle' size='small' text onClick={toggleExpanded}>
                    {expanded ? 'See Less' : 'See More'}
                </Button>
            )}
        </span>
    );
}

export default LongStringWithSeeMore;
