import LoaderButton from "../../components/common/loaderButton/LoaderButton";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Formik, ErrorMessage } from "formik";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { customVariablesService } from "../../services";
import { ScrollPanel } from "primereact/scrollpanel";
import AlertDialog from "../../components/common/AlertDialog/AlertDialog";
import { Skeleton } from "primereact/skeleton";
import { Checkbox } from "primereact/checkbox";
export default function CustomVariable({ testCaseId, isLocal }) {
    const initalValuesData = {
        name: isLocal ? "{{l_" : "{{g_",
        variable_id: "",
        value: "",
    };
    const toast = useRef();
    const [variableOptions, setVariableOptions] = useState([]);
    const [variableNames, setVariableNames] = useState({});
    const [variableList, setVariableList] = useState([]);
    const [filteredVariableList, setFilteredVariableList] = useState([]);
    const [listLoading, setlistLoading] = useState(false);
    const [initalValues, setInitalValues] = useState(initalValuesData);
    const [filter, setFilter] = useState({ name: "", variable_id: "" });

    const [withGlobals, setWithGlobals] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        customVariablesService
            .getVariableTypes(controller)
            .then(({ data }) => {
                setVariableOptions(data);
                setVariableNames(
                    data.reduce((acc, curr) => {
                        console.log(curr);
                        return { ...acc, [curr.executable_method]: curr.id };
                    }, {})
                );
            })
            .catch((err) => {
                console.log(err);
            });
        return () => {
            controller.abort();
        };
    }, []);

    useLayoutEffect(() => {
        setlistLoading(true);
        const params = {
            limit: 1000000,
            test_case_id: testCaseId,
            with_global: withGlobals ? withGlobals : null,
        };
        customVariablesService
            .getVariables(null, params)
            .then(({ data }) => {
                setVariableList(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setlistLoading(false));
    }, [testCaseId, withGlobals]);

    useLayoutEffect(() => {
        if (filter.name === "" && filter.variable_id === "") {
            setFilteredVariableList(variableList);
        } else {
            console.log(filter);
            const updatedList = variableList.filter((item) => {
                if (filter.name !== "" && filter.variable_id !== "" && item.name.includes(filter.name) && parseInt(item.variable_id) === parseInt(filter.variable_id)) return true;
                if (filter.name !== "" && item.name.includes(filter.name)) return true;
                if (filter.variable_id !== "" && parseInt(item.variable_id) === parseInt(filter.variable_id)) return true;
                return false;
            });
            setFilteredVariableList(updatedList);
        }
    }, [filter, variableList]);

    const onSubmit = async (values, { resetForm }) => {
        try {
            if (values.id) {
                const { data } = await customVariablesService.updateCustomVariables(values.id, { ...values, test_case_id: testCaseId });
                setVariableList((prevList) => prevList.map((item) => (item.id === data.id ? data : item)));
            } else {
                const { data } = await customVariablesService.createCustomVariable({ ...values, test_case_id: testCaseId });
                setVariableList([...variableList, data]);
            }
        } catch (error) {
            const { response } = error;
            if (response.status === 422) {
                const errMsg = Object.entries(response.data.errors).map((err) => <div>{err[1]}</div>);
                toast.current.show({ severity: "error", summary: "Error", detail: errMsg });
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Something went wrong" });
            }
        }
        resetForm();
        setInitalValues(initalValuesData);
    };
    console.log(initalValues);
    const validationSchema = Yup.object({
        name: Yup.string()
            .when("variable_id", (name, schema) => (isLocal ? schema.matches(/^{{l_.+}}$/, "Name must be in {{l_ }}") : schema.matches(/^{{g_.+}}$/, "Name must be in {{g_ }}")))
            .required("Name is required"),
        variable_id: Yup.string().required("Type is required"),
        value: Yup.string()
            .when("variable_id", (variable_id, schema) => {
                const { text, randomNumber, randomFloat, excel } = variableNames;
                const forLength = [text, randomNumber, randomFloat, excel];
                if (forLength.includes(variable_id)) {
                    return schema.required("This field is required");
                }
                return schema.notRequired();
            })
            .test("stringLengthForText", "Minimum string length can be 5 only.", (value, context) => {
                if (context.parent.variable_id === "1" && parseInt(value) < 5) {
                    return false;
                }
                return true;
            }),
    });
    const Err = ({ name }) => {
        return (
            <div style={{ color: "#ff4032" }}>
                <ErrorMessage name={name} />
            </div>
        );
    };

    const onChangeVaribleType = (e, setFieldValue) => setFieldValue("variable_id", e.target.value);

    const onFilter = (e) => {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <>
            <div className=" grid ">
                <div className="col-12">
                    <Toast ref={toast} />
                    <div className="p-1 border-round order-noround mb-1">
                        <h2 className="text-lg font-semibold ">Add Variable</h2>
                        <Formik initialValues={initalValues} onSubmit={onSubmit} enableReinitialize validationSchema={validationSchema}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <div>
                                    {/* <h5>Advanced</h5> */}
                                    <form onSubmit={handleSubmit}>
                                        <div className="p-fluid formgroup-inline gap-2 grid align-items-end px-3 py-2 ">
                                            <div className="field col-12 md:col-3 m-0">
                                                <label htmlFor="name">Name</label>
                                                <InputText name="name" type="text" onChange={handleChange} value={values.name} placeholder={"{{name}}"} />
                                                <Err name="name" />
                                            </div>
                                            <div className="field col-12 md:col-3 m-0">
                                                <label htmlFor="variable_id">Type</label>
                                                <select
                                                    className="p-inputtext p-component"
                                                    name="variable_id"
                                                    id="variable_id"
                                                    onChange={(e) => {
                                                        onChangeVaribleType(e, setFieldValue);
                                                    }}
                                                >
                                                    <option value="" selected={"" === values.variable_id}>
                                                        Select Type
                                                    </option>
                                                    {variableOptions.map(({ name, id }) => (
                                                        <option selected={id === values.variable_id} key={name} value={id}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <Err name="variable_id" />
                                            </div>
                                            {(parseInt(values.variable_id) === variableNames.text || parseInt(values.variable_id) === variableNames.randomNumber || parseInt(values.variable_id) === variableNames.randomFloat) && (
                                                <div className="field col-12 md:col-3 m-0">
                                                    <label htmlFor="value">Length</label>
                                                    <InputText
                                                        name="value"
                                                        type="number"
                                                        onChange={(e) => {
                                                            if (e.target.value >= 1) {
                                                                setFieldValue("value", e.target.value);
                                                            }
                                                        }}
                                                        value={values.value}
                                                    />
                                                    <Err name="value" />
                                                </div>
                                            )}
                                            {console.log(parseInt(values.variable_id) === variableNames.excel, parseInt(values.variable_id), variableNames.excel)}
                                            {parseInt(values.variable_id) === variableNames.excel && (
                                                <div className="field col-12 md:col-3 m-0">
                                                    <label htmlFor="value">Formula</label>
                                                    <InputText name="value" type="text" onChange={handleChange} value={values.value} />
                                                    <Err name="value" />
                                                </div>
                                            )}
                                            <div className="mb-2" style={{ textAlign: "right" }}>
                                                {/*<Button label="Cancel" type='button' icon="pi pi-times" className="p-button-text w-auto" />*/}
                                                <LoaderButton keepText type="submit" icon={!isSubmitting && "pi pi-check"} className="p-button-text w-auto shadow-1" loading={isSubmitting} spinnerStyle={{ borderTop: "3px solid #6366f1" }}>
                                                    Save
                                                </LoaderButton>
                                            </div>
                                        </div>

                                        {false && (
                                            <>
                                                <pre>values:{JSON.stringify(values, false, 2)}</pre>
                                                <pre>errors:{JSON.stringify(errors, false, 2)}</pre>
                                            </>
                                        )}
                                    </form>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="flex flex-column w-full p-4">
                        <div className={"p-3 bg-blue-900 border-round text-white mb-1"}>
                            <h2 className="text-lg font-semibold mb-0">All Variables</h2>
                            {isLocal && (
                                <div style={{ float: "right" }}>
                                    <label>
                                        <Checkbox className="mr-1" onChange={(e) => setWithGlobals(e.checked)} checked={withGlobals}>
                                            Globals
                                        </Checkbox>
                                        Globals
                                    </label>
                                </div>
                            )}
                        </div>
                    <div className="p-fluid formgrid grid mt-2  border-bottom-1 border-300 mb-2">
                        <div className="field col-12">
                            <h4 className=" text-base font-semibold mb-0">Filters —</h4>
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="name">Name</label>
                            <InputText name="name" type="text" placeholder={"{{name}}"} onChange={onFilter} />
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="variable_id">Type</label>
                            <select className="p-inputtext p-component" name="variable_id" onChange={onFilter}>
                                <option value="">Select Type</option>
                                {variableOptions.map(({ name, id }) => (
                                    <option key={name} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="bg-white shadow rounded-lg  w-full">
                        <ScrollPanel style={{ width: "100%", height: "400px" }}>
                            <div className="grid column-gap-3 row-gap-1">
                                {listLoading ? (
                                    <ListCardSkeleton />
                                ) : (
                                    filteredVariableList.map((item) => {
                                        const { text, randomNumber, randomFloat } = variableNames;
                                        const forLength = [text, randomNumber, randomFloat];
                                        return (
                                            <div key={item.id} className="py-2 font-medium flex align-items-center justify-content-between col-12 border-bottom-1 border-300">
                                                <span className="">{item.name}</span>
                                                <div>
                                                    <span className=" ">Type: {item?.variable?.name}</span>
                                                    {variableNames?.excel === item?.variable?.id && <span className=" border-left-1 mx-1"> Formula: {item.value} </span>}
                                                    {forLength.includes(item?.variable?.id) && <span className=" border-left-1 mx-1 "> Length: {item.value} </span>}
                                                </div>
                                                <ActionButtons item={item} setVariableList={setVariableList} setInitalValues={setInitalValues} />
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </ScrollPanel>
                    </div>
                </div>
            </div>
        </>
    );
}
function PencilIcon(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
            <path d="m15 5 4 4" />
        </svg>
    );
}

function TrashIcon(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 6h18" />
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
        </svg>
    );
}
const ActionButtons = ({ item, setVariableList, setInitalValues }) => {
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const onDelete = async () => {
        try {
            await customVariablesService.deleteCustomVariables(item.id);
            onDeleteAlertHide();
            setVariableList((prev) => prev.filter((v) => v.id !== item.id));
        } catch (error) {}
    };

    const onDeleteAlertHide = () => {
        setShowDeleteAlert(false);
    };

    const onEdit = () => {
        const { id, name, variable_id, value } = item;
        setInitalValues({ id, name, variable_id, value });
    };

    return (
        <>
            <AlertDialog message={"Are you sure you want to delete this variable?"} show={showDeleteAlert} onYes={onDelete} onNo={onDeleteAlertHide} onHide={onDeleteAlertHide} />

            <div className="flex items-center gap-2 ">
                <TrashIcon className=" cursor-pointer text-red-500" onClick={() => setShowDeleteAlert(true)} />
                <PencilIcon className=" cursor-pointer text-blue-500" onClick={onEdit} />
            </div>
        </>
    );
};

const ListCardSkeleton = () => {
    return (
        <>
            <div className="col-12">
                <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="100%" height="2rem"></Skeleton>
            </div>
        </>
    );
};
