import React from 'react'
import PropTypes from 'prop-types';
import './style.css'
import { Button } from 'primereact/button';
const LoaderButton = React.forwardRef(({ type, className, style, onClick, children, altText,
    loading, keepText, spinnerStyle, icon, size, tooltip, tooltipPosition }, ref) => {

    const Spinner = () => {
        return <span className="loader" style={{ ...spinnerStyle }}></span>
    }

    const renderBtnChild = () => {
        if (loading && altText) return altText
        if (loading && keepText) return <>{children} <Spinner /> </>
        if (loading) return <Spinner />
        return children
    }


    return (
        <Button
            type={type}
            label={renderBtnChild()}
            icon={icon}
            className={className}
            disabled={loading}
            style={style}
            onClick={onClick}
            size={size}
            ref={ref ?? null}
            raised
            tooltip={tooltip}
            tooltipOptions={{ position: tooltipPosition }}
        />

    )
})

LoaderButton.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    altText: PropTypes.string,
    loading: PropTypes.bool,
    keepText: PropTypes.bool,
    spinnerStyle: PropTypes.object
}
export default LoaderButton