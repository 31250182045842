import api from "../utils/api";
class GenericTermService {

    /**
     * 
     * @param {*} abortController 
     * @returns 
     */
    getProjectTypes(abortController) {
        const config = {
            url: '/project-types',
            method: 'get',
            ...(abortController && { signal: abortController.signal })
        }
        return api.request(config)
    }

}
const genericTermService = new GenericTermService()
export default genericTermService