import React, { useEffect, useState } from 'react'
import { DataTable } from "primereact/datatable"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { useQuery } from 'react-query';
import defectService from '../../services/defects.service';
import moment from 'moment';
import { Tag } from 'primereact/tag';
import { getBadgeType } from '../../utils/common';
import LongStringWithSeeMore from '../../utils/LongStringWithSeeMore';
import Modal from './Modal';
import StatusDropdowns from './statusDropdowns';
const Defects = () => {
    const [visible, setVisible] = useState(false);
    const [currentDefect, setCurrentDefect] = useState({});
    const [pagination, setPagination] = useState({
        first: 0,
        page: 0,
        limit: 10,
        total: 0
    })
    const columns = [
        { field: (data) => <LongStringWithSeeMore maxLength={100} content={data.title} />, header: "Assigned To" },
        { field: (data) => <Tag value={data?.assigned?.name} severity={getBadgeType(data?.assigned?.name)} />, header: "Assigned To" },
        { field: (data) => <StatusDropdowns defectId={data.id} statusId={data?.status?.id} /> 
        // <Tag value={data?.status?.name} severity={getBadgeType(data?.status?.name)} />
        , 
        header: "Status" },
        { field: (data) => moment(data.created_at ?? "").format("DD-MM-YYYY HH:MM A"), header: "Created At" },
        { field: (data) => <Tag value={data?.created_by?.name} severity={getBadgeType(data?.created_by?.name)} />, header: "Created By" },
    ]

    const controller = new AbortController();
    function calculatePageSize(itemHeight, pageHeight) {
        const itemsPerPage = Math.floor(pageHeight / itemHeight);
        return itemsPerPage;
    }
    const { isLoading, isError, refetch, data, isRefetching } = useQuery({

        queryKey: ['defects', pagination.page, pagination.limit],
        queryFn: async () => {
            const itemHeight = 50; // Replace with the actual height of your item/row
            const pageHeight = window.innerHeight; // Replace with the actual height of your page

            const pageSize = calculatePageSize(itemHeight, pageHeight);
            const params = {
                page: pagination.page + 1,
                limit: pageSize,

            }
            const response = await defectService.getDefects(controller, params);
            const { data } = response
            const { total } = data.meta
            setPagination((pg) => ({ ...pg,   limit: pageSize, total }))
            return data.data
        },

    })

    const actionBody = (row) => {

        return (
            <span className="">
                <Button icon="pi pi-eye" size='small' rounded
                    raised severity='info' className='action-button' onClick={() => { setVisible(true); setCurrentDefect(row) }}></Button>
            </span>
        )
    }
    const modalProps = {
        visible,
        setVisible,
        testPlanItem: currentDefect
    }
    if (isError) {
        return <p>Somthing went wrong</p>
    }
    return (
        <div className=" grid ">
            {currentDefect && <Modal  {...modalProps} />}
            <DataTable
                value={data ?? []}
                lazy
                first={pagination.first}
                totalRecords={pagination.total}
                paginator
                rows={pagination.limit}
                onPage={(event) => {
                    setPagination((pg) => {
                        return { ...pg, page: event.page, first: event.first }
                    })
                }}
                dataKey="id"
                loading={isLoading || isRefetching}
                className="datatable-responsive w-full"
                emptyMessage="No Defects found."
                filterDisplay="row"
                responsiveLayout="scroll" >
                {
                    columns.map((col, key) => (

                        <Column {...col} key={key} />
                    ))
                }
                <Column body={actionBody} header="Actions"  ></Column>

            </DataTable>

        </div>
    )
}

export default Defects