
import React, { useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';

const GalleriaView = React.forwardRef((props, ref) => {
    const [images, setImages] = useState(null);


    const responsiveOptions = [
        {
            breakpoint: '1500px',
            numVisible: 5
        },
        {
            breakpoint: '1024px',
            numVisible: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    useEffect(() => {
        props.images && setImages(prev =>
            prev = props.images
        )
    }, [props.images]);

    const itemTemplate = (item) => {
        return <img src={process.env.REACT_APP_STORAGE_PATH + item} alt={item} style={{ width: '100%', height: "100%", display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={process.env.REACT_APP_STORAGE_PATH + item} alt={item} style={{ display: 'block', maxWidth: "6rem" }} />;
    }

    return (
        <Galleria ref={ref} value={images} responsiveOptions={responsiveOptions} numVisible={9} style={{ maxWidth: '500px' }}
            circular fullScreen showItemNavigators item={itemTemplate} thumbnail={thumbnailTemplate} />

    )
})

export default GalleriaView