import React, { useRef } from 'react'
import { SelectButton } from 'primereact/selectbutton';
import { testPlanService } from '../../../../../services';
import { Toast } from 'primereact/toast';
import {
  useMutation
} from 'react-query'
const BlukStepsUpdate = ({ testRunnerId, suiteId, setTests, bulkStepsUpdate, setBulkStepsUpdate }) => {
  const toast = useRef(null);


  const { mutate, isLoading } = useMutation(
    async (data) => {
      const response = await testPlanService.bulkStepsUpdate(data)
      return response;
    },
    {
      onSettled: ({ data, message }, error) => {
        if (error) {
          // Handle error
          console.error('Error:', error);
          toast.current.show({ severity: 'error', summary: 'Error', detail: error?.response?.data?.message ?? error.message });
        } else {
          console.log(data, "data react query")
          setTests(prev => {
            prev = data.test_runner_steps; return prev
          });
          toast.current.show({ severity: 'success', summary: 'Steps', detail: message });
        }
      },
    }
  );

  const options = [{ name: "Pass All", value: true, constant: isLoading }, { name: "Fail All", value: false, constant: isLoading }];
  const bulkUpdate = (e) => {
    if (e.value !== null) {
      setBulkStepsUpdate(e.value)
      mutate({ test_runner_id: testRunnerId, test_suite_id: suiteId, is_passed: e.value, bulk_update: true })
    } else {
      setBulkStepsUpdate(prev => prev)
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <SelectButton size={"small"} value={bulkStepsUpdate} className='w-12rem' pt={{
        button: ({ context }) => ({ className: context.selected ? 'bg-green-500' : undefined })
      }}
        onChange={(e) => bulkUpdate(e)} options={options} optionLabel="name" optionValue='value' optionDisabled="constant" />
    </>
  )
}

export default BlukStepsUpdate