import React, { useState } from 'react'
import { testPlanService } from '../../../../services'
import { testPlanActions } from '../../../../store/actions';
import { useDispatch, useSelector } from "react-redux";
import AddTestCase from '../AddTestCase';
import TestPlanItemAddedSuitesList from './TestPlanItemAddedSuitesList';

export default function DefineTab() {
    const dispatch = useDispatch()

    const [selectedRows, setSelectedRows] = useState([])
    const [refreshList, doRefreshList] = useState(false);
    const { details } = useSelector((state) => state.testPlan)
    const selectedItemIndex = useSelector((state) => state.testPlan.activeItemIndex)
    const itemId = details.items[selectedItemIndex].id

    const addCaseSuiteCallback = () => {
        if (selectedItemIndex !== null) {

            testPlanService.getTestSuiteItemsFromItem(itemId)
                .then(({ data }) => {
                    dispatch({ type: testPlanActions.SET_ADDED_SUITES, data: data[0].added_suites })
                }).catch((err) => {
                    console.log(err)
                })
        }
    }

    const removeTestPlanItemCallback = () => doRefreshList((prev) => !prev)

    return <>
        <AddTestCase
            addCaseSuiteCallback={addCaseSuiteCallback}
            selectedRows={selectedRows}
            removeTestPlanItemCallback={removeTestPlanItemCallback}
        />
        <TestPlanItemAddedSuitesList
            addCaseSuiteCallback={addCaseSuiteCallback}
            selectedRowsCallback={(rows) => setSelectedRows(rows)}
            removeTestPlanItemCallback={removeTestPlanItemCallback}
            refreshList={refreshList}
            usingIn="define"
        />
    </>
}
