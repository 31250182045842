import React, { useEffect, useState, useRef } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useQuery, useMutation } from 'react-query';
import userService from '../../../services/user.service';
import defectService from '../../../services/defects.service';
import { Toast } from 'primereact/toast';

const CreateDefect = ({ visible, setVisible, testRunnerItem , refetchLogs }) => {
    const toast = useRef(null);
    const [currentItem, setCurrentItem] = useState({})
    const { id: test_runner_item_id, CAN_CREATE_DEFECT } = currentItem
    const validationSchema = yup.object().shape({
        title: yup.string().required("User Id is required"),
        assigned_to: yup.string().required("Select an user")
    });
    const [users, setUsers] = useState([])

    const { handleSubmit, control, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { isLoading, isError, refetch } = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            const response = await userService.getUsers()
            setUsers(response.data)
            console.log(response.data)
            return response.data
        },
        enabled: false,

    })

    useEffect(() => {
        refetch()
    }, [refetch])

    useEffect(() => {
        if (testRunnerItem?.defect) {
            setValue("title", testRunnerItem?.defect?.title)
            setValue("assigned_to", testRunnerItem?.defect?.assigned_to)
        }
        setCurrentItem(testRunnerItem)
    }, [testRunnerItem , setValue])
       const closeModal = () => {
        reset({
            title: "",
            assigned_to: "",
        })
        setCurrentItem({})
        setVisible(false)
    }

    const mutation = useMutation(async (newData) => {
        const response = await defectService.createDefect(newData)
        return response;
    }, {
        onError: (error) => {
            toast.current.show({ severity: 'warn', summary: 'Error', detail: error.response.data?.message ?? error.message });
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        },
        onSuccess: async (res) => {
            toast.current.show({ severity: 'success', summary: 'Info', detail: res.message });
            refetchLogs()
            closeModal()
        },
    });
 

    const onSubmit = (data) => {
        mutation.mutate({ ...data, test_runner_item_id: test_runner_item_id });
    };

    if (!users.length && isLoading) return <Button loading rounded size='small' className='action-button' severity='info' />

    if (isError) {
        return <Button label='Error' rounded size='small' className='action-button' severity='danger' />
    }
    return (
        <>
            <Toast ref={toast} />
            <Dialog header={`${CAN_CREATE_DEFECT ? "Create" : ""} Defect`} visible={visible} style={{ width: '50vw' }} onHide={() => closeModal()}>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="username">Title</label>
                        <Controller control={control}
                            name="title"
                            render={({ field: { onChange, value } }) => (
                                <InputText id="username" aria-describedby="username-help" value={value ?? ""} onChange={onChange}
                                    disabled={!CAN_CREATE_DEFECT}
                                />
                            )}
                        />
                        <p className="text-red-600">{errors.title?.message}</p>

                    </div>
                    <div className='flex flex-column gap-2 mt-3'>
                        <label htmlFor="assignedTo">Assigned To:</label>
                        <Controller
                            control={control}
                            name="assigned_to"
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    id="assigned_to"
                                    value={value ?? ""}
                                    onChange={onChange}
                                    options={users}
                                    optionLabel="name"
                                    optionValue='id'
                                    placeholder="Select an user"
                                    filter
                                    filterBy='name'
                                    disabled={!CAN_CREATE_DEFECT}
                                />

                            )}
                        />
                        <p className="text-red-600">{errors.assigned_to?.message}</p>
                    </div>
                    <span className={`${CAN_CREATE_DEFECT ? "p-buttonset" : ""}  text-right mt-5 block`}>
                        {CAN_CREATE_DEFECT ? <Button label='Create' type="submit" size='small'></Button> : ""}
                        <Button label='Cancel' type="button" size='small' severity='danger' onClick={closeModal}></Button>
                    </span>
                </form>


            </Dialog>
        </>
    )
}

export default CreateDefect